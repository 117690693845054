import RouterApp from "./router-config";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

ReactDOM.render(
  <div>
    <Provider store={store}>
      <RouterApp />
    </Provider>
  </div>,
  document.getElementById("root")
);
