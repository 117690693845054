import { Avatar, Space, Tooltip } from "antd";
import { FONT } from "constants/index";
import { UserRound, UserRoundPen, UserRoundX } from "lucide-react";

const items = [
  {
    key: "1",
    label: <a> Editar </a>,
  },
  {
    key: "2",
    label: <a> Desativar </a>,
  },
];

export const ColumnsUser = (innerWidth) => {
  const table = [
    {
      title: "Foto",
      dataIndex: "image",
      width: "10%",
      render: (text, row) => {
        return (
          <Avatar src={row.img} icon={<UserRound size={50} />} size={50} onError={(e) => console.log(e)}/>
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      textWrap: "word-break",
      ellipsis: true,
      width: "10%",
    },
  ];

  const tableDefault = [
    {
      title: "E-mail",
      dataIndex: "email",
      textWrap: "word-break",
      width: "30%",

      ellipsis: true,
    },
    {
      title: "Perfil",
      dataIndex: "profile",
      textWrap: "word-break",
      width: "15%",
      ellipsis: true,
    },
  ];

  if (innerWidth > 500) {
    table.push(...tableDefault);
  }

  table.push({
    title: "Ações",
    dataIndex: "action",
    width: "10%",

    render: (text, row) => {
      return (
        <Space size="middle">
          <Tooltip style={{ fontFamily: FONT }} title="Editar Usuário">
            <UserRoundPen
              onClick={() => row.edit(row)}
              size={22}
              style={{ cursor: "pointer", color: "#09ac9c" }}
            />
          </Tooltip>
          <Tooltip style={{ fontFamily: FONT }} title="Desativar Usuário">
            <UserRoundX
              onClick={() => row.disable(row)}
              size={22}
              style={{ cursor: "pointer", color: "#EC6D62" }}
            />
          </Tooltip>
        </Space>
      );
    },
  });
  return table;
};
