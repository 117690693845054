import Services from "api/ProjectApiConsumer";

export function getCashFlowByType(obj) {
  return Services.dashBoardService.getCashFlowByType(obj);
}

export function getCashFlowBalance(obj) {
  return Services.dashBoardService.getCashFlowBalance(obj);
}

export function getCashFlowBalanceData(obj) {
  return Services.dashBoardService.getCashFlowBalanceData(obj);
}


export function getKpiCashFlow(obj) {
  return Services.dashBoardService.getKpiCashFlow(obj);
}
export function getKpiCashFlowCompany(obj) {
  return Services.dashBoardService.getKpiCashFlowCompany(obj);
}
export function getManagerialAmount() {
  return Services.dashBoardService.getManagerialAmount();
}
export function getManagerialAccount() {
  return Services.dashBoardService.getManagerialAccount();
}
export function getManagerialCash() {
  return Services.dashBoardService.getManagerialCash();
}

export function getBanks() {
  return Services.dashBoardService.getBanks();
}

export function getCashFlowByTypeToDashboard(obj) {
  return Services.dashBoardService.getCashFlowByTypeToDashboard(obj);
}
