import Services from "api/ProjectApiConsumer";

export function login(loginRequest) {
  return Services.userService.login(loginRequest);
}

export function generateCode(email) {
  return Services.userService.generateCode(undefined, undefined, email);
}
export function newPassword(request) {
  return Services.userService.newPassword(request);
}
