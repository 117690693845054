import { ACCESS_TOKEN } from "constants/index";
import { sessionService } from "redux-react-session";

export function getToken() {
  if (localStorage.getItem(ACCESS_TOKEN)) {
    return localStorage.getItem(ACCESS_TOKEN);
  } else {
    return null;
  }
}

export function validSession() {
  return sessionService
    .loadUser()
    .then((user) => {
      return true;
    })
    .catch((err) => {
      return false;
    });
}
