import { createSlice } from "@reduxjs/toolkit";
import { USER_STORAGE } from "constants/index";

const initialState = {
  role: "",
};

export const loginSlice = createSlice({
  name: "login-user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLoginUser: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const { role } = JSON.parse(localStorage.getItem(USER_STORAGE));
      state.role = role;
    },
    setLogoutUser: (state) => {
      state.role = "";
    },
  },
});

export const { setLoginUser, setLogoutUser } = loginSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRole = (state) => state.loginUser.role;

export default loginSlice.reducer;
