import {
  Button,
  DatePicker,
  Result,
  Row,
  Spin,
  Form,
  Table,
  Select,
  Col,
} from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import moment from "moment";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getCostCenters } from "services/client/client-service";
import { notificationErroInternal } from "util/api-utils";
import { ColumnsCostCenterExpend } from "./columns-table";
dayjs.extend(customParseFormat);
const dateFormat = "YYYY";
const { Option } = Select;

const CostCenters = (props) => {
  const [loading, setLoading] = useState(false);
  const [filterStartDate, setStartDate] = useState(moment().format(dateFormat));
  const [filterEndDate, setEndDate] = useState(moment().format(dateFormat));
  const [data, setData] = useState([]);
  const [dataBkp, setDataBkp] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [typeDate, setTypeDate] = useState("VENCIMENTO");

  const refreshData = () => {
    getData();
  };

  const setFilterStartDate = (e) => {
    if (e == null) {
      setStartDate(moment().format(dateFormat));
    } else {
      setStartDate(e.format(dateFormat));
    }
  };

  const setFilterEndDate = (e) => {
    if (e == null) {
      setEndDate(moment().format(dateFormat));
    } else {
      setEndDate(e.format(dateFormat));
    }
  };

  function getData() {
    setLoading(true);
    const filters = {
      startDate: `${filterStartDate}-01-01`,
      endDate: `${filterEndDate}-12-31`,
      typeDate: typeDate,
    };
    getCostCenters(filters)
      .then((res) => {
        const yearDates = res.map((it) => it.yearDate);
        const keys = [...new Set(yearDates)];
        const groupedData = groupByCostCenter(res);
        groupedData.forEach((it) => {
          var categories = it.result[0].categories;
          const groupedDataCategories = groupByCostCenter(categories);
          it.categories = groupedDataCategories;
          it.showChildren = false;
          it.isHeader = true;
        });

        setData(groupedData);
        setDataBkp(groupedData);
        setDataKeys(keys);
        setLoading(false);
      })
      .catch((err) => {
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
        setData([]);
        setLoading(false);
      });
  }

  function groupByCostCenter(data) {
    const groupedData = data.reduce((groups, item) => {
      const { name } = item;
      if (!groups[name]) {
        groups[name] = [];
      }
      groups[name].push(item);
      return groups;
    }, {});
    const list = Object.entries(groupedData).map(([name, items]) => ({
      name,
      id: items[0].id,
      result: items,
    }));
    return list.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  useEffect(() => {
    getData();
  }, [props]);

  let size = 500;
  let sizeDiv = "100%";
  let column = ColumnsCostCenterExpend(dataKeys);

  return (
    <div className="container-cost-center">
      <Form>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item name="startDate" label="Ano Inicial">
              <DatePicker
                defaultValue={dayjs(filterStartDate, dateFormat)}
                format={dateFormat}
                picker="year"
                allowClear={false}
                placeholder="Selecione o ano inicial"
                onChange={setFilterStartDate}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="endDate" label="Ano Final">
              <DatePicker
                defaultValue={dayjs(filterEndDate, dateFormat)}
                format={dateFormat}
                picker="year"
                allowClear={false}
                placeholder="Selecione o ano final"
                onChange={setFilterEndDate}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="typeDate" label="Tipo">
              <Select
                defaultValue={typeDate}
                onChange={(value) => setTypeDate(value)}
              >
                <Option value="VENCIMENTO">Regime de Caixa</Option>
                <Option value="COMPETENCIA">Competência</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item>
              <Button type="primary" onClick={refreshData}>
                Filtrar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div style={{ width: sizeDiv }}>
        <div className="card-container-cost-center"> {"Centro de Custo"}</div>
        <Spin spinning={loading} size={"large"}>
          {dataKeys.length > 0 && (
            <Table
              className="table-cost-center"
              pagination={false}
              dataSource={data}
              size="small"
              columns={column}
              bordered
              scroll={{ x: size }}
              rowKey="id"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    var listUpdate = [];
                    dataBkp.forEach((item) => {
                      let showChildren = data
                        .filter((key) => key.id == item.id)
                        .map((obj) => obj.showChildren)[0];
                      if (item.id == record.id) {
                        showChildren = !record.showChildren;
                      }
                      listUpdate.push({
                        ...item,
                        isHeader: true,
                        showChildren: showChildren,
                      });

                      if (showChildren) {
                        item.categories.forEach((children) => {
                          listUpdate.push({
                            ...children,
                            isHeader: false,
                          });
                        });
                      }
                    });
                    setData(listUpdate);
                  },
                };
              }}
            />
          )}
          {dataKeys.length == 0 && !loading && (
            <Result status="404" subTitle="Desculpe, dados não encontrados" />
          )}
        </Spin>
      </div>
    </div>
  );
};
export default CostCenters;
