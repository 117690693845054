import { Button, Card, Col, Result, Row, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
  getManagerialAccount,
  getManagerialAmount,
  getManagerialCash,
} from "services/dashboard/dashboard-service";
import { notificationErroInternal } from "util/api-utils";
import { formatCurrency, formatDate } from "util/format";
import {
  ColumnsBank,
  ColumnsCash,
  ColumnsPaid,
  ColumnsReceive,
} from "./columns-table";
import { mountChartGauge } from "./func-chart-gauge";
import FusionCharts from "fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.gammel";
import ReactFC from "react-fusioncharts";
import ReactFusioncharts from "react-fusioncharts";
import * as Widgets from "fusioncharts/fusioncharts.widgets.js";
import { mountDataColumnAndLineCashFlow } from "./func-chart-bar-linha";
import { USER_STORAGE } from "constants/index";
import { DollarCircleOutlined, LineChartOutlined } from "@ant-design/icons";
import { mountChartPie } from "./func-chart-pie";
import { Landmark, CalendarClock, ChartColumn, FileInput } from "lucide-react";
import CustomRadioGroup from "./filter-radio-group";
import {
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { CustomEmptyState } from "views/util/emptyState";
const { Text } = Typography;
const padColor = "#EC6D62";
const receiveColor = "#0071FF";
const profitColor = "#FF9900";
ReactFC.fcRoot(FusionCharts, FusionTheme, Widgets);
FusionCharts.options["license"] = false;
const font = '"Montserrat", sans-serif';

const COLORS = [
  "#0071FF",
  "#737373",
  "#EE6A5F",
  "#12475B",
  "#22C55E",
  "#FA8F21",
];

const CustomTooltipPie = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p>{`${payload[0].name}:   ${formatCurrency(payload[0].value)}`}</p>
      </div>
    );
  }

  return null;
};
const CustomLegend = ({ payload }) => (
  <ul
    style={{
      listStyleType: "none",
      padding: 0,
      overflowX: "auto",
      height: 200,
    }}
  >
    {payload.map((entry, index) => (
      <li
        key={`item-${index}`}
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 4,
        }}
      >
        <span
          style={{
            display: "inline-block",
            width: 10,
            height: 10,
            backgroundColor: entry.color,
            marginRight: 8,
            borderRadius: 20,
          }}
        ></span>
        <Text style={{ fontFamily: font }}>{entry.value}</Text>
        <Text
          style={{ marginLeft: "auto", fontFamily: font, color: "#9FA1AA" }}
        >
          {formatCurrency(entry.payload.value)}
        </Text>
      </li>
    ))}
  </ul>
);

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "6px",
          fontFamily: font,
        }}
      >
        <p className="intro">{`Dia: ${formatDate(label)}`}</p>
        <p className="intro">
          Pagamentos:{" "}
          <span style={{ color: padColor }}>
            {formatCurrency(payload[0].value)}
          </span>
        </p>
        <p className="intro">
          Recebimentos:{" "}
          <span style={{ color: receiveColor }}>
            {formatCurrency(payload[1].value)}
          </span>
        </p>
        <p className="intro">
          Saldo Final:{" "}
          <span style={{ color: profitColor }}>
            {formatCurrency(payload[2].value)}
          </span>
        </p>
      </div>
    );
  }

  return null;
};

const Managerial = () => {
  const [dataAmount, setDataAmount] = useState({});
  const [dataAccounting, setDataAccounting] = useState({});
  const [dataCash, setDataCash] = useState({});

  useEffect(() => {
  
  }, []);

  function getDataAccount() {
    getManagerialAccount()
      .then((res) => {
        setDataAccounting(res);
      })
      .catch((err) => {
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  function getDataCash() {
    getManagerialCash()
      .then((res) => {
        setDataCash(res);
      })
      .catch((err) => {
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  function getDataAmount() {
    getManagerialAmount()
      .then((res) => {
        setDataAmount(res);
      })
      .catch((err) => {
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  return (
    <div className="container-managerial">
      <div style={{display:'none'}}>
        <Row gutter={[24, 24]}>
          <Col flex="0 0 33.33%">
            <Card
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <Landmark
                      size={25}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "18px", fontFamily: font }}>
                      Saldo em contas hoje
                    </Text>
                  </div>
                  <Button style={{ fontFamily: font, marginLeft: "10px" }}>
                    {dataAmount.nowDate}
                  </Button>
                </div>
              }
              bordered={false}
            >
              <Row style={{ paddingBottom: "10px" }}>
                <Text
                  style={{
                    fontFamily: font,
                    color: "#090D0F",
                    fontSize: "40px",
                  }}
                >
                  {formatCurrency(dataAmount.amount)}
                </Text>
              </Row>
              <Row>
                <Text
                  style={{
                    fontFamily: font,
                    color: "#9FA1AA",
                    fontSize: "14px",
                  }}
                >
                  Total
                </Text>
              </Row>
            </Card>
          </Col>
          <Col flex="0 0 33.33%">
            <Card
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <CalendarClock
                      size={25}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "18px", fontFamily: font }}>
                      Pagar hoje
                    </Text>
                  </div>
                  <Button style={{ fontFamily: font, marginLeft: "10px" }}>
                    {dataAmount.nowDate}
                  </Button>
                </div>
              }
              bordered={false}
            >
              <Row style={{ paddingBottom: "10px" }}>
                <Text
                  style={{
                    fontFamily: font,
                    color: "#FF3B30",
                    fontSize: "40px",
                  }}
                >
                  {formatCurrency(dataAmount.paid)}
                </Text>
              </Row>
              <Row>
                <Text
                  style={{
                    fontFamily: font,
                    color: "#9FA1AA",
                    fontSize: "14px",
                  }}
                >
                  Em atraso: {formatCurrency(dataAmount.latePaid)}
                </Text>
              </Row>
            </Card>
          </Col>
          <Col flex="0 0 33.33%">
            <Card
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <ChartColumn
                      size={25}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "18px", fontFamily: font }}>
                      Receber hoje
                    </Text>
                  </div>
                  <Button style={{ fontFamily: font, marginLeft: "10px" }}>
                    {dataAmount.nowDate}
                  </Button>
                </div>
              }
              bordered={false}
            >
              <Row style={{ paddingBottom: "10px" }}>
                <Text
                  style={{
                    fontFamily: font,
                    color: "#007AFF",
                    fontSize: "40px",
                  }}
                >
                  {formatCurrency(dataAmount.receive)}
                </Text>
              </Row>
              <Row>
                <Text
                  style={{
                    fontFamily: font,
                    color: "#9FA1AA",
                    fontSize: "14px",
                  }}
                >
                  Em atraso: {formatCurrency(dataAmount.lateReceive)}
                </Text>
              </Row>
            </Card>
          </Col>
        </Row>
        <CustomRadioGroup />
        <Row gutter={[24, 24]}>
          <Col flex="0 0 66.66%">
            <Card
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <ChartColumn
                      size={25}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "18px", fontFamily: font }}>
                      Fluxo de Caixa
                    </Text>
                  </div>
                </div>
              }
              style={{ width: "100%" }}
            >
              {dataAmount && dataAmount.futureBill?.length > 0 && (
                <ResponsiveContainer
                  width="100%"
                  height={906}
                  style={{ fontFamily: font }}
                >
                  <ComposedChart
                    data={dataAmount.futureBill}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="dueDate"
                      tickFormatter={(value) => value.substring(8)}
                    />
                    <YAxis tickFormatter={(value) => formatCurrency(value)} />

                    <Tooltip content={<CustomTooltip />} />
                    <Legend align="left" />
                    <Bar name="Pagamentos" dataKey="paid" fill={padColor} />
                    <Bar
                      name="Recebimentos"
                      dataKey="receive"
                      fill={receiveColor}
                    />
                    <Line
                      name="Saldo Final"
                      type="monotone"
                      dataKey="profit"
                      stroke={profitColor}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              )}
              {dataAmount && dataAmount.futureBill?.length == 0 && (
                <CustomEmptyState />
              )}
            </Card>
          </Col>
          <Col flex="0 0 33.33%">
            <Row>
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Landmark
                        size={25}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "18px", fontFamily: font }}>
                        Bancos
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%", height: "500px" }}
              >
                {dataAccounting && dataAccounting.bank?.length > 0 && (
                  <ResponsiveContainer width="100%" height={550} id="pieChart">
                    <PieChart>
                      <Pie
                        data={dataAccounting.bank}
                        dataKey="amount"
                        nameKey="name"
                        startAngle={180}
                        endAngle={0}
                        cx="50%"
                        cy="50%"
                        outerRadius={120}
                        label={({ name, value }) => formatCurrency(value)}
                      >
                        {dataAccounting.bank.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip content={<CustomTooltipPie />} />
                      <Legend content={<CustomLegend />} />
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </Card>
            </Row>
            <Row>
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Landmark
                        size={25}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "18px", fontFamily: font }}>
                        Limites
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%", height: "500px", marginTop: "10px" }}
              >
                {dataCash && dataCash.bank?.length > 0 && (
                  <ResponsiveContainer width="100%" height={550} id="pieChart2">
                    <PieChart>
                      <Pie
                        data={dataCash.bank}
                        dataKey="amount"
                        nameKey="name"
                        startAngle={180}
                        endAngle={0}
                        cx="50%"
                        cy="50%"
                        outerRadius={120}
                        label={({ name, value }) => formatCurrency(value)}
                      >
                        {dataCash.bank.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip content={<CustomTooltipPie />} />
                      <Legend content={<CustomLegend />} />
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </Card>
            </Row>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Card
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <Landmark
                      size={25}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "18px", fontFamily: font }}>
                      Contas a Receber
                    </Text>
                  </div>
                </div>
              }
              style={{ width: "100%", marginTop: "20px" }}
              id="tableCash"
            >
              <Table
                columns={ColumnsReceive}
                className={"card-accouting"}
                dataSource={dataAccounting.receive}
                showHeader={false}
                pagination={false}
                locale={{
                  emptyText: "Sem registro",
                }}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <FileInput
                      size={25}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "18px", fontFamily: font }}>
                      Contas a Pagar
                    </Text>
                  </div>
                </div>
              }
              style={{ width: "100%", marginTop: "20px" }}
              id="tableCash"
            >
              <Table
                columns={ColumnsPaid}
                className={"card-accouting"}
                dataSource={dataAccounting.paid}
                showHeader={false}
                pagination={false}
                locale={{
                  emptyText: "Sem registro",
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Managerial;
