import React, { useEffect, useState } from "react";
import { Spin, Row, Space, Card, Table, Typography, Select, Form } from "antd";
import { Medal } from "lucide-react";
import { FONT, ROLE_USER, USER_STORAGE } from "constants/index";
import { notificationErroInternal } from "util/api-utils";
import { ColumnsUser } from "./columns-table";
import { getUsersReviewsRanking, getUsersReviewsRankingSprint } from "services/review/review-service";
import { useSelector } from "react-redux";
import { selectRole } from "reducer/login/login-user";

const { Text } = Typography;

const RankingReview = (props) => {
  const [loading, setLoading] = useState(true);
  const [rangeAge, setRangeAge] = useState("GENERAL");
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [roleRedux, setRoleRedux] = useState(useSelector(selectRole));
  const [idUser, setIdUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [field, setField] = useState("point");
  const [titleField, setTitleField] = useState("Avaliação");
  const [sprintType, setSprintType] = useState("SPRINT_LINEAR");
  const [formSprint] = Form.useForm();

  useEffect(() => {
    switch (props.type) {
      case "REVIEW":
        setField("point");
        setTitleField("Avaliação");
        break;
      case "CMJ":
        setField("cmj");
        setTitleField("Salto");
        break;
      case "SPRINT":
        setField("sprintLinear");
        setTitleField("Tempo");
        break;
      default:
        break;
    }
    refreshData(rangeAge);
    form.setFieldsValue({
      rangeAnge: rangeAge,
    });
    formSprint.setFieldsValue({
      sprintType: sprintType,
    });
  }, []);

  useEffect(() => {
    const localStorageItem = localStorage.getItem(USER_STORAGE);
    if (localStorageItem != null) {
      const { role, id } = JSON.parse(localStorageItem);
      if (roleRedux == "" || role != roleRedux) {
        setRoleRedux(role);
      }
      setIdUser(id);
    }
  }, []);

  function refreshData(rangeAge, sprintType) {
    setLoading(true);
    setRangeAge(rangeAge);
    setSprintType(sprintType);
    switch (sprintType) {
      case "SPRINT_LINEAR":
        setField("sprintLinear");
        break;
      case "COD_SPRINT":
        setField("codSprint");
        break;
      default:
        break;
    }
    getData(rangeAge, sprintType);
  }

  function getData(rangeAge, sprintType) {
    let obj = {
      rangeAge: rangeAge,
      rankingType: props.type,
      sprintType: sprintType,
    };

    const cleanParam = Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => value !== undefined)
    );
    
    var funcExecute = getUsersReviewsRanking;
    if (props.type == "SPRINT") {
      var funcExecute = getUsersReviewsRankingSprint;

    }
    funcExecute(cleanParam)
      .then((res) => {
        res = res.map((obj) => ({
          ...obj,
        }));
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container-ranking-client">
      <Spin size="large" spinning={loading}>
        <Row gutter={24} style={{ padding: "10px", marginBottom: "-20px" }}>
          {roleRedux != ROLE_USER && (
            <Space>
              <Form form={form}>
                <Form.Item name="rangeAnge">
                  <Select
                    placeholder="Faixa Etária"
                    onChange={(event) => {
                      refreshData(event, sprintType);
                    }}
                    style={{ width: "240px" }}
                  >
                    <Option value="GENERAL">Geral</Option>
                    <Option value="ELEVEN_MINUS"> {"<11 anos"} </Option>
                    <Option value="TWELVE_FOURTEEN">12 a 14 anos</Option>
                    <Option value="FIFTEEN_EIGHTEEN">15 a 18 anos </Option>
                    <Option value="NINETEEN_PLUS">{">19 anos"}</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Space>
          )}
          {props.type == "SPRINT" && (
            <Space>
              <Form form={formSprint}>
                <Form.Item name="sprintType">
                  <Select
                    placeholder="Sprint"
                    onChange={(event) => {
                      refreshData(rangeAge, event);
                    }}
                    style={{ width: "240px" }}
                  >
                    <Option value="SPRINT_LINEAR">Sprint Linear</Option>
                    <Option value="COD_SPRINT"> Cod Sprint </Option>
                  </Select>
                </Form.Item>
              </Form>
            </Space>
          )}
        </Row>

        <Row gutter={[24, 24]} style={{ marginLeft: "0px" }}>
          <Space
            direction="vertical"
            size={"small"}
            style={{
              flex: "1 1 500px",
              minWidth: outerWidth < 501 ? "0px" : "500px",
            }}
          >
            <Card
              id="card-table"
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <Medal
                      size={18}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                      Classificação dos Alunos
                    </Text>
                  </div>
                </div>
              }
              bordered={false}
            >
              <Table
                columns={ColumnsUser(currentPage, titleField, field)}
                dataSource={data}
                pagination={{
                  pageSize: 10,
                  showSizeChanger: false,
                  onChange: onPageChange,
                }}
                locale={{
                  emptyText: "Sem registro",
                }}
                rowClassName={(record) => {
                  return record.id === idUser && roleRedux == ROLE_USER
                    ? "highlight-row"
                    : "";
                }}
              />
            </Card>
          </Space>
        </Row>
      </Spin>
    </div>
  );
};
export default RankingReview;
