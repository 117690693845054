import { Avatar } from "antd";
import { UserRound } from "lucide-react";

export const ColumnsUser = (currentPage, title, field) => {
  const table = [
    {
      title: "Classificação",
      dataIndex: "position",
      textWrap: "word-break",
      ellipsis: true,
      width: "15%",
      render: (item, record, index) => {
        const globalIndex = (currentPage - 1) * 10 + index + 1;
        return <>{globalIndex}º</>;
      },
    },
  ];
  const tableDefault = [
    {
      title: "Foto",
      dataIndex: "img",
      width: "15%",
      render: (text, row) => {
        return (
          <Avatar src={row.img} icon={<UserRound size={50} />} size={50} />
        );
      },
    },
  ];

  if (innerWidth > 500) {
    table.push(...tableDefault);
  }

  table.push(
    {
      title: "Nome",
      dataIndex: "name",
      textWrap: "word-break",
      ellipsis: true,
      width: "15%",
    },
    {
      title: "Idade",
      dataIndex: "age",
      textWrap: "word-break",
      ellipsis: true,
      width: "15%",
      render: (text, row) => {
        return text + " anos";
      },
    },
    {
      title: title,
      dataIndex: field,
      textWrap: "word-break",
      ellipsis: true,
      width: "15%",
    }
  );
  return table;
};
