import { formatCurrency } from "util/format";

const ColumnsFooterBillsReceive = [
  {
    title: "vencimento",
    dataIndex: "vencimento",
    render: (text, record) => <div>{"Total"}</div>,
  },
  {
    title: "forncedor",
    dataIndex: "forncedor",
  },
  {
    title: "categoria",
    dataIndex: "categoria",
  },
  {
    title: "Entrada",
    dataIndex: "amount",
    render: (text) => {
      return <div style={{ float: "right" }}>{formatCurrency(text)}</div>;
    },
  },
];

export default ColumnsFooterBillsReceive;
