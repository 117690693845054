import React, { useState } from "react";
import { Radio } from "antd";

const CustomRadioGroup = () => {
  const [value, setValue] = useState("15 Dias");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div
      style={{
        padding: "6px",
        background: "#dbdbdb",
        borderRadius: "6px",
        display: "inline-block",
        marginTop: "10px",
      }}
    >
      <Radio.Group value={value} onChange={handleChange}>
        <Radio.Button
          value="15 Dias"
          style={{
            borderRadius: "6px",
            color: "#181B1F",
            fontFamily: '"Montserrat", sans-serif',
            border:'none',
            padding: '3px 60px 0px 60px',
            marginBottom: '6px'

          }}
        >
          15 Dias
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default CustomRadioGroup;
