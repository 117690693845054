export const formatCurrency = (value) => {
  if (value && !isNaN(value)) {
    return `R$ ${formatNumber(value)}`;
  } else {
    return `R$ ${formatNumber(0)}`;
  }
};

export const formatNumber = (value) => {
  if (typeof value === "string") value = parseFloat(value);
  if (typeof value !== "number") value = 0;
  return value
    .toFixed(2)
    .toString()
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+,)/g, "$1.");
};

export const formatCurrencyWithoutRS = (value) => {
  if (value && !isNaN(value)) {
    return `${formatNumber(value)}`;
  } else {
    return `${formatNumber(0)}`;
  }
};

export const formatDate = (dateString) => {
  if (!dateString) {
    return "";
  }
  const day = dateString.substring(8);
  const month = dateString.substring(5, 7);
  const year = dateString.substring(0, 4);

  return `${day}/${month}/${year}`;
};
