import React, { useEffect, useState } from "react";
import { Spin, Typography, Modal, Button } from "antd";

import { FONT, USER_STORAGE } from "constants/index";
import { notificationErroInternal, notificationSucess } from "util/api-utils";
import DrawerApp from "components/drawer-app";
import {
  createSprint,
  deleteSprint,
  getReviewUserById,
  getSprintUserById,
  putSprint,
} from "services/review/review-service";
import FormUserResult from "./reviews-drawer-result";
import FormUserResultPoint from "./reviews-drawer-result-point";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import FormNewRecord from "./reviews-drawer-new-record";
dayjs.extend(customParseFormat);
const dateFormat = "DD/MM/YYYY";

const ReviewsClientUser = () => {
  const [loading, setLoading] = useState(true);
  const [imgAvatar, setImgAvatar] = useState("");
  const [dataDrawer, setDataDrawer] = useState({});
  const [openResultPoint, setOpenResultPoint] = useState(false);
  const [dataSprint, setDataSprint] = useState([]);
  const [editSprintId, setEditSprintId] = useState(null);
  const formRefRecord = React.useRef(null);
  const [openRecord, setOpenRecord] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const localStorageItem = localStorage.getItem(USER_STORAGE);
    if (localStorageItem != null) {
      const { id } = JSON.parse(localStorageItem);
      getReviewUserById(id)
        .then((res) => {
          setDataDrawer(res);
          setImgAvatar(res.img);
          setLoading(false);
          setUserId(id)
        })
        .catch((err) => {
          setLoading(false);
          notificationErroInternal(
            "Aconteceu um erro inesperado, por favor, tente mais tarde."
          );
        });
    }
  }, []);

  const onCloseResultPoint = () => {
    setOpenResultPoint(false);
  };

  const showDrawerRecord = () => {
    setOpenRecord(true);
  };

  const onCloseRecord = () => {
    formRefRecord.current?.resetFields();
    setEditSprintId(null);
    setOpenRecord(false);
  };

  function editSprint(row) {
    setLoading(true);
    setEditSprintId(row.id);
    setTimeout(() => {
      formRefRecord.current?.setFieldsValue({
        date: row.sprintDate ? dayjs(row.sprintDate) : null,
        typeRecord: row.typeRecord,
        record: row.record,
      });
      setLoading(false);
    }, 300);
  }
  function deleteSprintItem(row) {
    confirm({
      title: (
        <div>
          <CircleAlert size={22} style={{ marginBottom: "-5px" }} />
          <span style={{ marginLeft: "10px", fontFamily: FONT }}>
            Tem certeza que deseja deletar o sprint do dia '
            {moment(row.sprintDate).format("DD/MM/YYYY")}' ?
          </span>
        </div>
      ),
      icon: null,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        setLoading(true);
        deleteSprint(row.id)
          .then(() => {
            getDataSprintUserById(userId, dataDrawer.name);
            notificationSucess();
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
          });
      },
      onCancel() {},
    });
  }

  function getDataSprintUserById(id, name) {
    getSprintUserById(id)
      .then((res) => {
        setTimeout(() => {
          res = res.map((obj) => ({
            ...obj,
            edit: editSprint,
            delete: deleteSprintItem,
          }));
          setDataSprint(res);
          formRefRecord.current?.setFieldsValue({
            name: name,
          });
          setLoading(false);
        }, 300);
        showDrawerRecord();
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  const handleNewSprint = () => {
    setLoading(true);
    formRefRecord?.current
      ?.validateFields()
      .then((values) => {
        values.userId = userId;
        values.date = values.date.format(dateFormat);

        var funcSave = createSprint;
        if (editSprintId != null) {
          funcSave = putSprint;
          values.id = editSprintId;
        }
        funcSave(values)
          .then(() => {
            formRefRecord.current?.setFieldsValue({
              date: "",
              typeRecord: "",
              record: "",
            });
            getDataSprintUserById(
              userId,
              dataDrawer.name,
            );
            setEditSprintId(null);
            notificationSucess();
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
          });
      })
      .catch((errors) => {
        setLoading(false);
        console.log(errors);
      });
  };
  function record() {
    setLoading(true);
    setImgAvatar(imgAvatar);
    getDataSprintUserById(userId, dataDrawer.name);
  }
  return (
    <div className="container-reviews-client">
      <div style={{ textAlign: "end" }}>
        <Button
          style={{
            fontFamily: FONT,
            background: "#14B8A6",
            color: "#ffffff",
          }}
          onClick={() => setOpenResultPoint(true)}
        >
          Sistema de Pontos
        </Button>
        <Button
          style={{
            fontFamily: FONT,
            background: "#1677FF",
            color: "#ffffff",
          }}
          onClick={() => record()}
        >
          Sprints
        </Button>
      </div>
      <Spin size="large" spinning={loading}>
        <FormUserResult dataDrawer={dataDrawer} imgAvatar={imgAvatar} />
      </Spin>

      <DrawerApp
        open={openResultPoint}
        onClose={onCloseResultPoint}
        title={"Sistema de Pontos"}
        body={<FormUserResultPoint />}
        size={"smail"}
        isOnlyView={true}
      />

      <DrawerApp
        open={openRecord}
        onClose={onCloseRecord}
        title={"Sprints"}
        body={
          <FormNewRecord
            dataDrawer={dataSprint}
            imgAvatar={imgAvatar}
            loading={loading}
            formRef={formRefRecord}
          />
        }
        actionSave={handleNewSprint}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
    </div>
  );
};
export default ReviewsClientUser;
