import { formatCurrency } from "util/format";

export const ColumnsCostCenterExpend = (data) => {
  const columns = [];
  columns.push({
    title: <div style={{ textAlign: "center" }}>Centro de Custo</div>,
    dataIndex: "name",
    textWrap: "word-break",
    ellipsis: true,
    width: "50%",
    fixed: "left",
    responsive: ["sm"],
    render(text, record) {
      let color = record.isHeader ? "#BABBC2" : "";

      return {
        props: {
          style: { background: color },
        },
        children: <div style={{ cursor: "pointer" }}>{text}</div>,
      };
    },
  });
  if (data) {
    data.forEach((element) => {
      columns.push({
        title: <div style={{ textAlign: "center" }}>{element}</div>,
        dataIndex: "amount",
        textWrap: "word-break",
        ellipsis: true,
        width: "100%",
        fixed: "left",
        responsive: ["sm"],
        children: [
          {
            title: <div style={{ textAlign: "center" }}>Receita</div>,
            dataIndex: "amount",
            textWrap: "word-break",
            ellipsis: true,
            width: "100%",
            fixed: "left",
            responsive: ["sm"],
            children: [],
            render(text, record) {
              const item = record.result.filter(
                (it) => it.yearDate == element
              )[0];
              const value = item ? formatCurrency(item.receive) : "-";
              let color = record.isHeader ? "#BABBC2" : "";

              return {
                props: {
                  style: { background: color },
                },
                children: <div style={{ cursor: "pointer" }}>{value}</div>,
              };
            },
          },
          {
            title: <div style={{ textAlign: "center" }}>Despesa</div>,
            dataIndex: "amount",
            textWrap: "word-break",
            ellipsis: true,
            width: "100%",
            fixed: "left",
            responsive: ["sm"],
            children: [],
            render(text, record) {
              const item = record.result.filter(
                (it) => it.yearDate == element
              )[0];
              const value = item ? formatCurrency(item.paid) : "-";
              let color = record.isHeader ? "#BABBC2" : "";

              return {
                props: {
                  style: { background: color },
                },
                children: <div style={{ cursor: "pointer" }}>{value}</div>,
              };
            },
          },
        ],
      });
    });
  }
  columns.push({
    title: <div style={{ textAlign: "center" }}>Total</div>,
    dataIndex: "total",
    textWrap: "word-break",
    ellipsis: true,
    width: "100%",
    fixed: "left",
    responsive: ["sm"],
    render(text, record) {
      const total = record.result.reduce(
        (total, item) => total + item.total,
        0
      );
      let color = record.isHeader ? "#BABBC2" : "";

      return {
        props: {
          style: { background: color },
        },
        children: (
          <div div style={{ cursor: "pointer" }}>
            {formatCurrency(total)}
          </div>
        ),
      };
    },
  });
  return columns;
};
