import { formatCurrency } from "util/format";

const ColumnsFooterBillsPay = [
  {
    title: "data",
    dataIndex: "data",
    render: (text, record) => <div>{"Total"}</div>,
  },
  {
    title: "entrada",
    dataIndex: "entrada",
  },
  {
    title: "saida",
    dataIndex: "saida",
  },
  {
    title: "amount",
    dataIndex: "amount",
    render: (text) => {
      return <div style={{ float: "right" }}>{formatCurrency(text)}</div>;
    },
  },
];

export default ColumnsFooterBillsPay;
