import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Progress,
  Row,
  Space,
  Spin,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getBanks,
  getCashFlowByTypeToDashboard,
  getKpiCashFlow,
} from "services/dashboard/dashboard-service";
import { notificationErroInternal } from "util/api-utils";
import { formatCurrency, formatCurrencyWithoutRS } from "util/format";
import FusionCharts from "fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.gammel";
import ReactFC from "react-fusioncharts";
import ReactFusioncharts from "react-fusioncharts";
import * as Widgets from "fusioncharts/fusioncharts.widgets.js";
import Powercharts from "fusioncharts/fusioncharts.powercharts";

import {
  mountCharBar,
  mountCharBarCategory,
  mountCharBarClient,
  mountCharFunil,
  mountChartLine,
  mountChartMultBar,
  mountChartMultLine,
  mountChartPie,
  mountChartProfitBar,
  mountTreemap,
  mountWaterFall,
  replaceMonth,
} from "./func-chart";
import dayjs from "dayjs";
import moment from "moment";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getIncome, getStatus } from "services/client/client-service";
import { CSVLink } from "react-csv";
dayjs.extend(customParseFormat);
const dateFormat = "MM/YYYY";
import TreeMap from "fusioncharts/fusioncharts.treemap";
import ExcelExport from "fusioncharts/fusioncharts.excelexport";

ReactFC.fcRoot(
  FusionCharts,
  Powercharts,
  TreeMap,
  Widgets,
  ExcelExport,
  FusionTheme
);
FusionCharts.options["license"] = false;
const { info } = Modal;

const Expenses = () => {
  const [data, setData] = useState({
    cashFlowYear: [],
    cashFlowMonth: [],
    clientTopTen: [],
    sumCategory: [],
    categoryTopTen: [],
  });
  const [loading, setLoading] = useState(false);
  const [dataQuarter, setDataQuarter] = useState(false);
  const [filterStartDate, setStartDate] = useState(moment().format(dateFormat));
  const [filterEndDate, setEndDate] = useState(moment().format(dateFormat));
  const [dataIncome, setDataIncome] = useState([]);
  const [loadingIncome, setLoadingIcome] = useState(false);
  const [filterBanks, setFilterBanks] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);

  useEffect(() => {
    
  }, []);
  function getData() {
    setLoading(true);

    const filter = {
      type: "CONTA_A_PAGAR",
      start: filterStartDate,
      end: filterEndDate,
    };
    getCashFlowByTypeToDashboard(filter)
      .then((res) => {
        let cashFlowQuarter = [];
        var index = 1;
        var sum = 0;
        var quarter = 1;
        let monthOrder = [];
        monthOrder.push(...res.cashFlowMonth);
        monthOrder.push({ amount: 0 });
        monthOrder.forEach((m) => {
          let amount = m.amount;
          if (index <= 3) {
            sum = sum + amount;
            index++;
          } else {
            cashFlowQuarter.push({
              datetrunc: "Q" + quarter,
              amount: sum.toFixed(2),
            });
            sum = m.amount;
            quarter++;
            index = 2;
          }
        });
        setDataQuarter(cashFlowQuarter);
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  function getMappingDataIncome(filterStatus, filterBanks) {
    setLoadingIcome(true);

    const filter = {
      start: filterStartDate,
      end: filterEndDate,
      status: filterStatus,
      banks: filterBanks,
    };
    getIncome(filter)
      .then((res) => {
        let dataIncome = res.mapping.filter((f) => f.mapping.length == 0);
        dataIncome = dataIncome.sort((a, b) => a.order - b.order);

        setDataIncome(dataIncome);
        setLoadingIcome(false);
      })
      .catch((err) => {
        setLoadingIcome(false);

        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  function showConfirm(title, div) {
    info({
      title: <div style={{ textAlign: "center" }}>{title}</div>,
      icon: null,
      content: div,
      width: "90%",
      centered: true,
      getContainer() {},
      onOk() {},
    });
  }
  const setFilterStartDate = (e) => {
    if (e == null) {
      setStartDate(moment().format(dateFormat));
    } else {
      setStartDate(e.format(dateFormat));
    }
  };
  const setFilterEndDate = (e) => {
    if (e == null) {
      setEndDate(moment().format(dateFormat));
    } else {
      setEndDate(e.format(dateFormat));
    }
  };
  const refreshData = () => {
    getData();
    getMappingDataIncome(filterStatus, filterBanks);
  };
  return (
    <div style={{display: 'none'}}>
      <Row style={{ padding: "10px" }}>
        <Form layout={"inline"}>
          <Form.Item name="startDate" label="Mês Inicial">
            <DatePicker
              defaultValue={dayjs(filterStartDate, dateFormat)}
              format={dateFormat}
              picker="month"
              allowClear={false}
              placeholder="Selecione o mês inicial"
              onChange={setFilterStartDate}
            />
          </Form.Item>
          <Form.Item name="endDate" label="Mês Final">
            <DatePicker
              defaultValue={dayjs(filterEndDate, dateFormat)}
              format={dateFormat}
              picker="month"
              allowClear={false}
              placeholder="Selecione o mês final"
              onChange={setFilterEndDate}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" onClick={refreshData}>
              Filtrar
            </Button>
          </Form.Item>
        </Form>
      </Row>
      <div className="welcome"> DESPESAS</div>
      <div style={{ background: "#ECECEC" }}>
        <Spin size="large" spinning={loading}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="container-card">
                <div
                  className="card-default"
                  style={{ minHeight: "340px", maxHeight: "340px" }}
                >
                  <Space>
                    <div>Despesa Anual</div>
                    <Button
                      type="link"
                      onClick={() =>
                        showConfirm(
                          "Despesa Anual",
                          <ReactFusioncharts
                            type="column2d"
                            width="95%"
                            height="300%"
                            dataFormat="JSON"
                            dataSource={mountCharBar(
                              data.cashFlowYear,
                              "Despesa Anual"
                            )}
                          />
                        )
                      }
                    >
                      Expandir
                    </Button>
                  </Space>

                  <ReactFusioncharts
                    type="column2d"
                    width="95%"
                    height="80%"
                    dataFormat="JSON"
                    dataSource={mountCharBar(
                      data.cashFlowYear,
                      "Despesa Anual"
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="container-card">
                <div
                  className="card-default"
                  style={{ minHeight: "340px", maxHeight: "340px" }}
                >
                  <Space>
                    <div>Despesa Mensal</div>
                    <Button
                      type="link"
                      onClick={() =>
                        showConfirm(
                          "Despesa Mensal",
                          <ReactFusioncharts
                            type="line"
                            width="95%"
                            height="300%"
                            dataFormat="JSON"
                            dataSource={mountChartLine(
                              data.cashFlowMonth,
                              "Despesa Mensal"
                            )}
                          />
                        )
                      }
                    >
                      Expandir
                    </Button>
                  </Space>

                  <ReactFusioncharts
                    type="line"
                    width="95%"
                    height="80%"
                    dataFormat="JSON"
                    dataSource={mountChartLine(
                      data.cashFlowMonth,
                      "Despesa Mensal"
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="container-card">
                <div
                  className="card-default"
                  style={{ minHeight: "340px", maxHeight: "340px" }}
                >
                  <Space>
                    <div>Despesa por trimestre</div>
                    <Button
                      type="link"
                      onClick={() =>
                        showConfirm(
                          "Despesa por trimestre",
                          <ReactFusioncharts
                            type="column2d"
                            width="95%"
                            height="300%"
                            dataFormat="JSON"
                            dataSource={mountCharBar(
                              dataQuarter,
                              "Despesa por trimestre"
                            )}
                          />
                        )
                      }
                    >
                      Expandir
                    </Button>
                  </Space>

                  <ReactFusioncharts
                    type="column2d"
                    width="95%"
                    height="80%"
                    dataFormat="JSON"
                    dataSource={mountCharBar(
                      dataQuarter,
                      "Despesa por trimestres"
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="container-card">
                <div className="card-default" style={{ minHeight: "340px" }}>
                  <Space>
                    <div>
                      Realizado x {data.showBudget ? "Orçado" : "Ano Anterior"}
                    </div>
                    <Button
                      type="link"
                      onClick={() =>
                        showConfirm(
                          "Realizado x " +
                            (data.showBudget ? "Orçado" : "Ano Anterior"),
                          <ReactFusioncharts
                            type="mscolumn2d"
                            width="95%"
                            height="300%"
                            dataFormat="JSON"
                            dataSource={mountChartMultBar(
                              data.cashFlowMonth,
                              "Realizado x " +
                                (data.showBudget ? "Orçado" : "Ano Anterior"),
                              data.showBudget
                            )}
                          />
                        )
                      }
                    >
                      Expandir
                    </Button>
                  </Space>
                  <ReactFusioncharts
                    type="mscolumn2d"
                    width="95%"
                    height="80%"
                    dataFormat="JSON"
                    dataSource={mountChartMultBar(
                      data.cashFlowMonth,
                      "Realizado x " +
                        (data.showBudget ? "Orçado" : "Ano Anterior"),
                      data.showBudget
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="container-card">
                <div
                  className="card-default"
                  style={{ minHeight: "340px", maxHeight: "340px" }}
                >
                  <Space>
                    <div>Top 10 Categorias</div>
                    <Button
                      type="link"
                      onClick={() =>
                        showConfirm(
                          "Top 10 Categorias",
                          <ReactFusioncharts
                            type="bar2d"
                            width="95%"
                            height="300%"
                            dataFormat="JSON"
                            dataSource={mountCharBarCategory(
                              data.categoryTopTen,
                              "Top 10 Categorias"
                            )}
                          />
                        )
                      }
                    >
                      Expandir
                    </Button>
                  </Space>

                  <ReactFusioncharts
                    type="bar2d"
                    width="95%"
                    height="80%"
                    dataFormat="JSON"
                    dataSource={mountCharBarCategory(
                      data.categoryTopTen,
                      "Top 10 Categorias"
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="container-card">
                <div
                  className="card-default"
                  style={{ minHeight: "340px", maxHeight: "340px" }}
                >
                  <Space>
                    <div>Top 10 Fornecedores</div>
                    <Button
                      type="link"
                      onClick={() =>
                        showConfirm(
                          "Top 10 Fornecedores",
                          <ReactFusioncharts
                            type="bar2d"
                            width="95%"
                            height="300%"
                            dataFormat="JSON"
                            dataSource={mountCharBarClient(
                              data.clientTopTen,
                              "Top 10 Fornecedores"
                            )}
                          />
                        )
                      }
                    >
                      Expandir
                    </Button>
                  </Space>

                  <ReactFusioncharts
                    type="bar2d"
                    width="95%"
                    height="80%"
                    dataFormat="JSON"
                    dataSource={mountCharBarClient(
                      data.clientTopTen,
                      "Top 10 Clientes"
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="container-card">
                <div
                  className="card-default"
                  style={{ minHeight: "600px", maxHeight: "600px" }}
                >
                  <Space>
                    <div>Composição Categoria</div>
                    <Button
                      type="link"
                      onClick={() =>
                        showConfirm(
                          "Composição Categoria",
                          <ReactFusioncharts
                            type="doughnut2d"
                            width="95%"
                            height="500%"
                            dataFormat="JSON"
                            dataSource={mountChartPie(
                              data.sumCategory,
                              "Composição Categoria"
                            )}
                          />
                        )
                      }
                    >
                      Expandir
                    </Button>
                  </Space>

                  <ReactFusioncharts
                    type="doughnut2d"
                    width="95%"
                    height="90%"
                    dataFormat="JSON"
                    dataSource={mountChartPie(
                      data.sumCategory,
                      "Composição Categoria"
                    )}
                  />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="container-card">
                <div
                  className="card-default"
                  style={{ minHeight: "600px", maxHeight: "600px" }}
                >
                  <Space>
                    <div>Funil de grupos</div>

                    <Button
                      type="link"
                      onClick={() =>
                        showConfirm(
                          "Funil de grupos",
                          <ReactFusioncharts
                            type="funnel"
                            width="95%"
                            height="500%"
                            dataFormat="JSON"
                            dataSource={mountCharFunil(
                              dataIncome,
                              "Funil de grupos"
                            )}
                          />
                        )
                      }
                    >
                      Expandir
                    </Button>
                  </Space>
                  <Spin spinning={loadingIncome} />

                  <ReactFusioncharts
                    type="funnel"
                    width="95%"
                    height="88%"
                    dataFormat="JSON"
                    dataSource={mountCharFunil(dataIncome, "Funil de grupos")}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
};
export default Expenses;
