import React, { useEffect, useState } from "react";

import {
  Row,
  Input,
  Typography,
  Form,
  Col,
  DatePicker,
  InputNumber,
  Divider,
  Avatar,
  Switch,
  Spin,
  Space,
  Button,
} from "antd";
import { UserRound } from "lucide-react";

import { FONT } from "constants/index";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { notificationErroInternal, notificationSucess } from "util/api-utils";
import {
  getReviewUserOpenById,
  putReview,
} from "services/review/review-service";
dayjs.extend(customParseFormat);

const { Text } = Typography;
const dateFormat = "DD/MM/YYYY";

const FormNewUserReview = (props) => {
  const formRef = React.useRef(null);
  const [reviewCa, setReviewCa] = useState(false);
  const [reviewCvNew, setReviewCvNew] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [imgAvatar, setImgAvatar] = useState("");
  const [showSprints, setShowSprints] = useState(false);

  useEffect(() => {
    setLoadingData(true);
    setImgAvatar(props.user?.img);
    setShowSprints(props.showSprints);
    if (props.user != null && props.user.id != null && props.showSprints == true) {
      getReviewUserOpenById(props.user.id)
        .then((res) => {
          formRef.current?.setFieldsValue({
            name: props.user.name,
            codSprint: res.codSprint,
            linearSprint: res.linearSprint,
          });
          setLoadingData(false);
        })

        .catch((err) => {
          setLoadingData(false);
          notificationErroInternal(
            "Aconteceu um erro inesperado, por favor, tente mais tarde."
          );
        });
    }
    if (props.editRow) {
      const row = props.editRow;
      setReviewCa(row.load1Ca != null);
      setReviewCvNew(row.load1CvNew != null);
      formRef.current?.setFieldsValue({
        name: props.user.name,
        weight: row.weight,
        date: row.reviewDate ? dayjs(row.reviewDate) : null,
        cmj: row.cmj,
        sj: row.sj,
        dj: row.dj,
        djBox: row.djBox,
        load1: row.load1,
        load2: row.load2,
        load3: row.load3,
        load4: row.load4,
        load5: row.load5,
        load6: row.load6,
        load1Ca: row.load1Ca,
        load2Ca: row.load2Ca,
        load3Ca: row.load3Ca,
        load4Ca: row.load4Ca,
        load5Ca: row.load5Ca,
        load6Ca: row.load6Ca,
        load1CvNew: row.load1CvNew,
        load2CvNew: row.load2CvNew,
        load3CvNew: row.load3CvNew,
        load4CvNew: row.load4CvNew,
        load5CvNew: row.load5CvNew,
        load6CvNew: row.load6CvNew,
        vmp1: row.vmp1,
        vmp2: row.vmp2,
        vmp3: row.vmp3,
        vmp4: row.vmp4,
        vmp5: row.vmp5,
        vmp6: row.vmp6,
        vmp1Ca: row.vmp1Ca,
        vmp2Ca: row.vmp2Ca,
        vmp3Ca: row.vmp3Ca,
        vmp4Ca: row.vmp4Ca,
        vmp5Ca: row.vmp5Ca,
        vmp6Ca: row.vmp6Ca,
        vmp1CvNew: row.vmp1CvNew,
        vmp2CvNew: row.vmp2CvNew,
        vmp3CvNew: row.vmp3CvNew,
        vmp4CvNew: row.vmp4CvNew,
        vmp5CvNew: row.vmp5CvNew,
        vmp6CvNew: row.vmp6CvNew,
        nameCvNew: row.nameCvNew,
        v1RmCvNew: row.v1RmCvNew,
      });
      setLoadingData(false);
    }
  }, [props]);

  const handleNewReview = () => {
    setLoadingData(true);

    formRef?.current
      ?.validateFields()
      .then((values) => {
        values.id = props.editId;
        values.userId = props.user.id;
        values.djBox = !values.djBox ? 30 : values.djBox;
        values.date = values.date.format(dateFormat);
        putReview(values)
          .then(() => {
            setLoadingData(false);
            if (props.onClose) {
              props.onClose(props.user.id, props.listClosed);
            }
            if (props.getData) {
              props.getData();
            } else {
              notificationSucess();
            }
            formRef.current?.resetFields();
          })
          .catch(() => {
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
            setLoadingData(false);
          });
      })
      .catch((errors) => {
        setLoadingData(false);
        console.log(errors);
      });
  };

  const onCloseDrawer = () => {
    props.onClose(props.user.id, props.listClosed);
  };
  return (
    <>
      <Spin spinning={loadingData}>
        <Form ref={formRef} layout="vertical">
          <Row justify="end" style={{marginBottom:'25px'}}>
            <Space>
              <Button style={{ fontFamily: FONT }} onClick={onCloseDrawer}>
                Cancelar
              </Button>
              <Button
                style={{ fontFamily: FONT }}
                onClick={handleNewReview}
                type="primary"
              >
                Salvar
              </Button>
            </Space>
          </Row>
          <Row
            gutter={16}
            style={{ textAlign: "center", marginBottom: "30px" }}
          >
            <Col span={24}>
              <Avatar
                src={imgAvatar}
                icon={<UserRound size={120} />}
                size={120}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="name"
                label={<span style={{ fontFamily: FONT }}>Aluno</span>}
              >
                <Input disabled={true} style={{ fontFamily: FONT }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="date"
                label={<span style={{ fontFamily: FONT }}>Data</span>}
                rules={[
                  {
                    required: true,
                    message: "É necessário informar a data da avaliação",
                  },
                ]}
              >
                <DatePicker
                  style={{ fontFamily: FONT, width: "100%" }}
                  placeholder={["", ""]}
                  format={dateFormat}
                  suffixIcon={null}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="weight"
                label={<span style={{ fontFamily: FONT }}>Peso</span>}
                rules={[
                  {
                    required: true,
                    message: "É necessário informar o peso do aluno",
                  },
                ]}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col>
              <Switch
                checkedChildren="Avaliar C-A"
                unCheckedChildren="Avaliar C-A"
                checked={reviewCa}
                onChange={(checked) => setReviewCa(checked)}
              />
            </Col>
            <Col>
              <Switch
                checkedChildren="Avaliar C-V Exercício"
                unCheckedChildren="Avaliar C-V Exercício"
                checked={reviewCvNew}
                onChange={(checked) => setReviewCvNew(checked)}
              />
            </Col>
          </Row>

          <Divider />
          <Row gutter={16}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Text
                style={{
                  fontSize: "15px",
                  fontFamily: FONT,
                }}
              >
                Saltos
              </Text>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={12}>
              <Form.Item
                name="cmj"
                label={<span style={{ fontFamily: FONT }}>CMJ</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="sj"
                label={<span style={{ fontFamily: FONT }}>SJ</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={12}>
              <Form.Item
                name="dj"
                label={<span style={{ fontFamily: FONT }}>DJ</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="djBox"
                label={<span style={{ fontFamily: FONT }}>Altura Caixa</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  defaultValue={30}
                  value={30}
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          {showSprints && (
            <>
              <Row gutter={16}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                    }}
                  >
                    Sprints
                  </Text>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="linearSprint"
                    label={
                      <span style={{ fontFamily: FONT }}>Linear Sprint</span>
                    }
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="codSprint"
                    label={<span style={{ fontFamily: FONT }}>Cod Sprint</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
            </>
          )}
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Text
                style={{
                  fontSize: "15px",
                  fontFamily: FONT,
                }}
              >
                Força Relativa C-V Agachamento
              </Text>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={12}>
              <Form.Item
                name="load1"
                label={<span style={{ fontFamily: FONT }}>LOAD 1</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vmp1"
                label={<span style={{ fontFamily: FONT }}>VMP 1</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={12}>
              <Form.Item
                name="load2"
                label={<span style={{ fontFamily: FONT }}>LOAD 2</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vmp2"
                label={<span style={{ fontFamily: FONT }}>VMP 2</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={12}>
              <Form.Item
                name="load3"
                label={<span style={{ fontFamily: FONT }}>LOAD 3</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vmp3"
                label={<span style={{ fontFamily: FONT }}>VMP 3</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={12}>
              <Form.Item
                name="load4"
                label={<span style={{ fontFamily: FONT }}>LOAD 4</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vmp4"
                label={<span style={{ fontFamily: FONT }}>VMP 4</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={12}>
              <Form.Item
                name="load5"
                label={<span style={{ fontFamily: FONT }}>LOAD 5</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vmp5"
                label={<span style={{ fontFamily: FONT }}>VMP 5</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={12}>
              <Form.Item
                name="load6"
                label={<span style={{ fontFamily: FONT }}>LOAD 6</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vmp6"
                label={<span style={{ fontFamily: FONT }}>VMP 6</span>}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          {reviewCa && (
            <>
              <Divider />
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                    }}
                  >
                    Força Relativa C-A
                  </Text>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="load1Ca"
                    label={<span style={{ fontFamily: FONT }}>LOAD 1</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="vmp1Ca"
                    label={<span style={{ fontFamily: FONT }}>VMP 1</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="load2Ca"
                    label={<span style={{ fontFamily: FONT }}>LOAD 2</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="vmp2Ca"
                    label={<span style={{ fontFamily: FONT }}>VMP 2</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="load3Ca"
                    label={<span style={{ fontFamily: FONT }}>LOAD 3</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="vmp3Ca"
                    label={<span style={{ fontFamily: FONT }}>VMP 3</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="load4Ca"
                    label={<span style={{ fontFamily: FONT }}>LOAD 4</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="vmp4Ca"
                    label={<span style={{ fontFamily: FONT }}>VMP 4</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="load5Ca"
                    label={<span style={{ fontFamily: FONT }}>LOAD 5</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="vmp5Ca"
                    label={<span style={{ fontFamily: FONT }}>VMP 5</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="load6Ca"
                    label={<span style={{ fontFamily: FONT }}>LOAD 6</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="vmp6Ca"
                    label={<span style={{ fontFamily: FONT }}>VMP 6</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {reviewCvNew && (
            <>
              <Divider />
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                    }}
                  >
                    Força Relativa C-V Novo Exercício
                  </Text>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="nameCvNew"
                    label={<span style={{ fontFamily: FONT }}>Exercício</span>}
                  >
                    <Input style={{ fontFamily: FONT }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="v1RmCvNew"
                    label={<span style={{ fontFamily: FONT }}>V1 RM</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="load1CvNew"
                    label={<span style={{ fontFamily: FONT }}>LOAD 1</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="vmp1CvNew"
                    label={<span style={{ fontFamily: FONT }}>VMP 1</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="load2CvNew"
                    label={<span style={{ fontFamily: FONT }}>LOAD 2</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="vmp2CvNew"
                    label={<span style={{ fontFamily: FONT }}>VMP 2</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="load3CvNew"
                    label={<span style={{ fontFamily: FONT }}>LOAD 3</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="vmp3CvNew"
                    label={<span style={{ fontFamily: FONT }}>VMP 3</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="load4CvNew"
                    label={<span style={{ fontFamily: FONT }}>LOAD 4</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="vmp4CvNew"
                    label={<span style={{ fontFamily: FONT }}>VMP 4</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="load5CvNew"
                    label={<span style={{ fontFamily: FONT }}>LOAD 5</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="vmp5CvNew"
                    label={<span style={{ fontFamily: FONT }}>VMP 5</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12}>
                  <Form.Item
                    name="load6CvNew"
                    label={<span style={{ fontFamily: FONT }}>LOAD 6</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="vmp6CvNew"
                    label={<span style={{ fontFamily: FONT }}>VMP 6</span>}
                  >
                    <InputNumber
                      decimalSeparator=","
                      style={{ fontFamily: FONT, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Spin>
    </>
  );
};
export default FormNewUserReview;
