import React, { useEffect, useState } from "react";
import { Row, Col, Avatar, Typography } from "antd";
import {  USER_STORAGE } from "constants/index";
import { GetPropsHeader } from "./header-custom";
import { getById } from "services/user/user-service";
import { UserRound } from "lucide-react";

const { Text } = Typography;
const font = "'Montserrat', sans-serif";
const Header = ({ logout }) => {
  const [userName, setUserName] = useState("");
  const customHeader = GetPropsHeader(location.pathname);
  const [imgAvatar, setImgAvatar] = useState("");

  useEffect(() => {
    const localStorageItem = localStorage.getItem(USER_STORAGE);
    if (localStorageItem != null) {
      const { name, id } = JSON.parse(localStorageItem);
      setUserName(name);
      getById(id).then((res) => {
        setImgAvatar(res.img)
      });
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        background: "#E0E2E7",
      }}
    >
      <div style={{ flex: 1, marginLeft: "30px" }}>
        {customHeader.pathIcon}
        <Text strong style={{ fontFamily: font, color: "#9FA1AA" }}>
          {customHeader.pathName}
        </Text>
      </div>
      <div style={{ marginRight: "20px", textAlign: "end" }}>
        <Text strong style={{ fontFamily: font }}>
          {userName}
        </Text>
        <br />
        <div
          className="logout"
          style={{
            fontFamily: font,
            cursor: "pointer",
          }}
          onClick={logout}
        >
          Sair
        </div>
      </div>
      <Row align="middle">
        <Col>
          <Avatar src={imgAvatar} icon={<UserRound size={50} />} size={50} />
        </Col>
      </Row>
    </div>
  );
};

export default Header;
