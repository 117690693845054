import {
  Row,
  Input,
  Typography,
  Form,
  Col,
  DatePicker,
  InputNumber,
  Divider,
  Avatar,
  Switch,
} from "antd";
import { UserRound } from "lucide-react";

import { FONT } from "constants/index";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const { Text } = Typography;
const dateFormat = "DD/MM/YYYY";

export const formNewUserCicle = (
  formRef,
  imgAvatar,
  reviewCa,
  setReviewCa,
  showSprints,
  reviewCvNew,
  setReviewCvNew
) => {
  return (
    <>
      <Form ref={formRef} layout="vertical">
        <Row gutter={16} style={{ textAlign: "center", marginBottom: "30px" }}>
          <Col span={24}>
            <Avatar
              src={imgAvatar}
              icon={<UserRound size={120} />}
              size={120}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="name"
              label={<span style={{ fontFamily: FONT }}>Aluno</span>}
            >
              <Input disabled={true} style={{ fontFamily: FONT }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="date"
              label={<span style={{ fontFamily: FONT }}>Data</span>}
              rules={[
                {
                  required: true,
                  message: "É necessário informar a data da avaliação",
                },
              ]}
            >
              <DatePicker
                style={{ fontFamily: FONT, width: "100%" }}
                placeholder={["", ""]}
                format={dateFormat}
                suffixIcon={null}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="weight"
              label={<span style={{ fontFamily: FONT }}>Peso</span>}
              rules={[
                {
                  required: true,
                  message: "É necessário informar o peso do aluno",
                },
              ]}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <Switch
              checkedChildren="Avaliar C-A"
              unCheckedChildren="Avaliar C-A"
              checked={reviewCa}
              onChange={(checked) => setReviewCa(checked)}
            />
          </Col>
          <Col>
            <Switch
              checkedChildren="Avaliar C-V Exercício"
              unCheckedChildren="Avaliar C-V Exercício"
              checked={reviewCvNew}
              onChange={(checked) => setReviewCvNew(checked)}
            />
          </Col>
        </Row>

        <Divider />
        <Row gutter={16}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Text
              style={{
                fontSize: "15px",
                fontFamily: FONT,
              }}
            >
              Saltos
            </Text>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={12}>
            <Form.Item
              name="cmj"
              label={<span style={{ fontFamily: FONT }}>CMJ</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="sj"
              label={<span style={{ fontFamily: FONT }}>SJ</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={12}>
            <Form.Item
              name="dj"
              label={<span style={{ fontFamily: FONT }}>DJ</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="djBox"
              label={<span style={{ fontFamily: FONT }}>Altura Caixa</span>}
            >
              <InputNumber
                decimalSeparator=","
                defaultValue={30}
                value={30}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        {showSprints && (
          <>
            <Row gutter={16}>
              <Col span={24} style={{ textAlign: "center" }}>
                <Text
                  style={{
                    fontSize: "15px",
                    fontFamily: FONT,
                  }}
                >
                  Sprints
                </Text>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="linearSprint"
                  label={
                    <span style={{ fontFamily: FONT }}>Linear Sprint</span>
                  }
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="codSprint"
                  label={<span style={{ fontFamily: FONT }}>Cod Sprint</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
          </>
        )}
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Text
              style={{
                fontSize: "15px",
                fontFamily: FONT,
              }}
            >
              Força Relativa C-V Agachamento
            </Text>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={12}>
            <Form.Item
              name="load1"
              label={<span style={{ fontFamily: FONT }}>LOAD 1</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="vmp1"
              label={<span style={{ fontFamily: FONT }}>VMP 1</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={12}>
            <Form.Item
              name="load2"
              label={<span style={{ fontFamily: FONT }}>LOAD 2</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="vmp2"
              label={<span style={{ fontFamily: FONT }}>VMP 2</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={12}>
            <Form.Item
              name="load3"
              label={<span style={{ fontFamily: FONT }}>LOAD 3</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="vmp3"
              label={<span style={{ fontFamily: FONT }}>VMP 3</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={12}>
            <Form.Item
              name="load4"
              label={<span style={{ fontFamily: FONT }}>LOAD 4</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="vmp4"
              label={<span style={{ fontFamily: FONT }}>VMP 4</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={12}>
            <Form.Item
              name="load5"
              label={<span style={{ fontFamily: FONT }}>LOAD 5</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="vmp5"
              label={<span style={{ fontFamily: FONT }}>VMP 5</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={12}>
            <Form.Item
              name="load6"
              label={<span style={{ fontFamily: FONT }}>LOAD 6</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="vmp6"
              label={<span style={{ fontFamily: FONT }}>VMP 6</span>}
            >
              <InputNumber
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        {reviewCa && (
          <>
            <Divider />
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={24} style={{ textAlign: "center" }}>
                <Text
                  style={{
                    fontSize: "15px",
                    fontFamily: FONT,
                  }}
                >
                  Força Relativa C-A
                </Text>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="load1Ca"
                  label={<span style={{ fontFamily: FONT }}>LOAD 1</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="vmp1Ca"
                  label={<span style={{ fontFamily: FONT }}>VMP 1</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="load2Ca"
                  label={<span style={{ fontFamily: FONT }}>LOAD 2</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="vmp2Ca"
                  label={<span style={{ fontFamily: FONT }}>VMP 2</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="load3Ca"
                  label={<span style={{ fontFamily: FONT }}>LOAD 3</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="vmp3Ca"
                  label={<span style={{ fontFamily: FONT }}>VMP 3</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="load4Ca"
                  label={<span style={{ fontFamily: FONT }}>LOAD 4</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="vmp4Ca"
                  label={<span style={{ fontFamily: FONT }}>VMP 4</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="load5Ca"
                  label={<span style={{ fontFamily: FONT }}>LOAD 5</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="vmp5Ca"
                  label={<span style={{ fontFamily: FONT }}>VMP 5</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="load6Ca"
                  label={<span style={{ fontFamily: FONT }}>LOAD 6</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="vmp6Ca"
                  label={<span style={{ fontFamily: FONT }}>VMP 6</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {reviewCvNew && (
          <>
            <Divider />
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={24} style={{ textAlign: "center" }}>
                <Text
                  style={{
                    fontSize: "15px",
                    fontFamily: FONT,
                  }}
                >
                  Força Relativa C-V Novo Exercício
                </Text>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={24}>
                <Form.Item
                  name="nameCvNew"
                  label={<span style={{ fontFamily: FONT }}>Exercício</span>}
                >
                  <Input style={{ fontFamily: FONT }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="load1CvNew"
                  label={<span style={{ fontFamily: FONT }}>LOAD 1</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="vmp1CvNew"
                  label={<span style={{ fontFamily: FONT }}>VMP 1</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="load2CvNew"
                  label={<span style={{ fontFamily: FONT }}>LOAD 2</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="vmp2CvNew"
                  label={<span style={{ fontFamily: FONT }}>VMP 2</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="load3CvNew"
                  label={<span style={{ fontFamily: FONT }}>LOAD 3</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="vmp3CvNew"
                  label={<span style={{ fontFamily: FONT }}>VMP 3</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="load4CvNew"
                  label={<span style={{ fontFamily: FONT }}>LOAD 4</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="vmp4CvNew"
                  label={<span style={{ fontFamily: FONT }}>VMP 4</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="load5CvNew"
                  label={<span style={{ fontFamily: FONT }}>LOAD 5</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="vmp5CvNew"
                  label={<span style={{ fontFamily: FONT }}>VMP 5</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "15px" }}>
              <Col span={12}>
                <Form.Item
                  name="load6CvNew"
                  label={<span style={{ fontFamily: FONT }}>LOAD 6</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="vmp6CvNew"
                  label={<span style={{ fontFamily: FONT }}>VMP 6</span>}
                >
                  <InputNumber
                    decimalSeparator=","
                    style={{ fontFamily: FONT, width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </>
  );
};
