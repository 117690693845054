import { ACCESS_TOKEN, USER_STORAGE } from "constants/index";
import jwt_decode from "jwt-decode";
const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const PATCH = "PATCH";
const DELETE = "DELETE";
const DEFAULT_METHODS = [GET, POST, PATCH, DELETE, PUT];
const HEADERS = new Headers({
  "Content-Type": "application/json",
});

export default class RestAPI {
  constructor(url) {
    this.url = url;
    this.endpoints = { listing: {} };
  }

  addEndpoint = (endpoint, name, method, isPrivate) => {
    this.endpoints[name || endpoint] = (body) =>
      this.request(`${this.url}/${endpoint}`, HEADERS, method, body, isPrivate);
  };

  addRichParametersEndpoint = (endpoint, name, method, isPrivate) => {
    this.endpoints[name || endpoint] = (body, queryParams, urlParam) =>
      this.request(
        `${this.url}/${endpoint}/${urlParam}${this.makeParams(queryParams)}`,
        HEADERS,
        method,
        body,
        isPrivate
      );
  };

  addEndpointWithParams = (endpoint, name, method, isPrivate) => {
    this.endpoints[name || endpoint] = (body, params) =>
      this.request(
        `${this.url}/${endpoint}${this.makeParams(params)}`,
        HEADERS,
        method,
        body,
        isPrivate
      );
  };

  addCRUDEndpoint = (entityName, methods) => {
    this.endpoints[entityName.split("/").join("")] = this.createCRUDOperations(
      entityName,
      methods ? methods : DEFAULT_METHODS
    );
  };

  addListingEndpoint = (entityName) => {
    this.endpoints.listing[entityName] = this.createCRUDOperations(
      `listing/${entityName}`,
      [GET]
    );
  };

  createCRUDOperations = (entity, methods) => {
    let operations = {
      getAll: () => {},
      get: () => {},
      create: () => {},
      update: () => {},
      delete: () => {},
    };
    const entityUrl = `${this.url}/${entity}`;

    methods.forEach((method) => {
      switch (method) {
        case GET:
          operations.getAll = () => this.request(entityUrl, HEADERS, GET, true);
          operations.get = (id) =>
            this.request(`${entityUrl}/${id}`, HEADERS, GET, true);
          break;

        case POST:
          operations.create = (body) =>
            this.request(entityUrl, HEADERS, POST, body, true);
          break;

        case PATCH:
          operations.update = (body) =>
            this.request(`${entityUrl}/${body.id}`, HEADERS, POST, body, true);
          break;

        case DELETE:
          operations.delete = (id) =>
            this.request(`${entityUrl}/${id}`, HEADERS, DELETE, true);
          break;

        default:
          break;
      }
    });

    return operations;
  };

  request = (url, headers, method, body, isPrivate) => {
    if (localStorage.getItem(ACCESS_TOKEN) && isPrivate) {
      headers.set("Authorization", localStorage.getItem(ACCESS_TOKEN));
    }

    //console.log("FETCHING URL: " + url);
    return fetch(url, {
      headers: headers,
      method: method,
      body: body ? JSON.stringify(body) : null,
    })
      .then((response) =>
        this.handleErrors(response, body, url, headers, method)
      )
      .then(this.saveToken)
      .then(this.checkBody)
      .then(this.returnBody);
  };

  handleErrors = (response, body, url, headers, method) => {
    if (!response.ok) {
      throw Error(
        JSON.stringify(
          {
            type: response.type,
            status: response.status,
            message: response.message,
            statusText: response.statusText,
            urlRequest: url,
            headers: headers,
            method: method,
            body: body,
            response: response,
          },
          null,
          1
        )
      );
    }
    return response;
  };

  saveToken = (response) => {
    if (response.headers.get("authorization")) {
      let token = response.headers.get("authorization");
      let tokenUser = token.replace("bearer ", "");
      const { id, name, sub, role, img } = jwt_decode(tokenUser);
      localStorage.setItem(ACCESS_TOKEN, token);
      localStorage.setItem(
        USER_STORAGE,
        JSON.stringify({
          id: id,
          name: name,
          sub: sub,
          role: role,
          img: img,
        })
      );
    }
    return response;
  };

  checkBody = (response) => {
    return response.text();
  };

  returnBody = (bodyPlainText) => {
    if (bodyPlainText.length !== 0) {
      return JSON.parse(bodyPlainText);
    } else {
      return {};
    }
  };

  makeParams = (data) => {
    return data && Object.keys(data).length
      ? "?" +
          Object.keys(data)
            .map((key) => [key, data[key]].map(encodeURIComponent).join("="))
            .join("&")
      : "";
  };
}
