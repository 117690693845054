import { notification } from "antd";
import React from "react";

export function notificationErroInternal(err) {
  let description = <div style={{ fontFamily: "Inter" }}>{err}</div>;

  let errorMessage = {
    message: "Ops!",
    description: description,
  };
  notification.error(errorMessage);
}

export function notificationInfo(err) {
  let description = <div style={{ fontFamily: "Inter" }}>{err}</div>;

  let errorMessage = {
    message: "Atenção!",
    description: description,
  };
  notification.info(errorMessage);
}

export function notificationSucess() {
  let description = (
    <div style={{ fontFamily: "Inter" }}>{"Seus dados foram salvo."}</div>
  );

  let msg = {
    message: "Sucesso!",
    description: description,
  };
  notification.success(msg);
}
export function notificationSucessMsg(message) {
  let description = <div style={{ fontFamily: "Inter" }}>{message}</div>;

  let msg = {
    message: "Sucesso!",
    description: description,
  };
  notification.success(msg);
}
