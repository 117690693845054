import Services from "api/ProjectApiConsumer";

export function getUsers(obj) {
  return Services.userAdminService.getUsers(undefined, obj);
}

export function saveUser(obj) {
  return Services.userAdminService.saveUser(obj);
}
export function putUser(obj) {
  return Services.userAdminService.putUser(obj);
}

export function deleteUser(id) {
  return Services.userAdminService.deleteUser(undefined, undefined, id);
}
export function getById(id) {
  return Services.userAdminService.getById(undefined, undefined, id);
}

