import { formatCurrency } from "util/format";

export const ColumnsReceive = [
  {
    title: "Name",
    dataIndex: "name",
    textWrap: "word-break",
    ellipsis: true,
    render: (text) => {
      return <div>• {text}</div>;
    },
  },

  {
    title: "Valor",
    dataIndex: "amount",
    render: (text) => {
      return (
        <div style={{ color: "#007AFF", fontWeight: "bold", textAlign: "end" }}>
          {formatCurrency(text)}
        </div>
      );
    },
  },
];

export const ColumnsPaid = [
  {
    title: "Name",
    dataIndex: "name",
    textWrap: "word-break",
    ellipsis: true,
    render: (text) => {
      return <div>• {text}</div>;
    },
  },

  {
    title: "Valor",
    dataIndex: "amount",
    render: (text) => {
      return (
        <div style={{ color: "#FF3B30", fontWeight: "bold", textAlign: "end" }}>
          {formatCurrency(text)}
        </div>
      );
    },
  },
];
export const ColumnsBank = [
  {
    title: "Name",
    dataIndex: "name",
    textWrap: "word-break",
    ellipsis: true,
  },

  {
    title: "Valor",
    dataIndex: "amount",
    render: (text) => {
      return (
        <div style={{ color: "#2A8DEB", fontWeight: "bold", textAlign: "end" }}>
          {formatCurrency(text)}
        </div>
      );
    },
  },
];

export const ColumnsCash = [
  {
    title: "Name",
    dataIndex: "name",
  },

  {
    title: "Valor",
    dataIndex: "amount",
    render: (text) => {
      return (
        <div style={{ color: "#2A8DEB", fontWeight: "bold", textAlign: "end" }}>
          {formatCurrency(text)}
        </div>
      );
    },
  },
];
