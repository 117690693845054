import React, { useState, useEffect } from "react";

import { Typography, Form, Tabs } from "antd";

import { FONT } from "constants/index";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import FormNewUserReview from "./reviews-drawer-new-review";
dayjs.extend(customParseFormat);

const FormNewUserReviewMultiple = (props) => {
  const [items, setItems] = useState([]);

  const onCloseList = (id, listClosed) => {
    const listItems = listClosed.filter((item) => item.id != id);

    const itemsData = listClosed
      .filter((item) => item.id != id)
      ?.map((item) => {
        return {
          label: item.name,
          key: item.id,
          children: (
            <FormNewUserReview
              user={item}
              showSprints={true}
              onClose={onCloseList}
              listClosed={listItems}
            />
          ),
        };
      });
    if (itemsData.length == 0) {
      props.onClose();
    }
    setItems(itemsData);
  };

  useEffect(() => {
    const itemsData = props.data?.map((item) => {
      return {
        label: item.name,
        key: item.id,
        children: (
          <FormNewUserReview
            user={item}
            showSprints={true}
            onClose={onCloseList}
            listClosed={props.data}
          />
        ),
      };
    });
    setItems(itemsData);
  }, [props]);

  return (
    <Tabs
      defaultActiveKey="1"
      centered
      size="large"
      style={{ fontFamily: FONT }}
      items={items}
    />
  );
};
export default FormNewUserReviewMultiple;
