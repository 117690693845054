import React from "react";
import { Tabs } from "antd";
import { FONT } from "constants/index";

import RankingReview from "./ranking-review";

const RankingClient = () => {
  const items = [
    {
      label: `Avaliação`,
      key: "review",
      children: <RankingReview type={"REVIEW"} />,
    },
    {
      label: `CMJ`,
      key: "cmj",
      children: <RankingReview type={"CMJ"} />,
    },
    {
      label: `Sprints`,
      key: "sprint",
      children: <RankingReview type={"SPRINT"} />,
    },
  ];

  return (
    <Tabs
      defaultActiveKey="1"
      centered
      size="large"
      style={{ fontFamily: FONT }}
      items={items}
    />
  );
};
export default RankingClient;
