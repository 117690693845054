import React, { useEffect } from "react";
import { Button, Drawer, Space } from "antd";
import { FONT } from "constants/index";
import { CircleX } from "lucide-react";

const DrawerApp = (props) => {
  useEffect(() => {}, [props]);
  return (
    <>
      <Drawer
        title={props.title}
        size={props.size}
        onClose={props.onClose}
        open={props.open}
        style={{ fontFamily: FONT }}
        closeIcon={<CircleX size={18} />}
        width={props.width}
        extra={
          props.isOnlyView ? (
            ""
          ) : props.extra ? (
            props.extra
          ) : (
            <Space>
              <Button style={{ fontFamily: FONT }} onClick={props.onClose}>
                Cancelar
              </Button>
              <Button
                style={{ fontFamily: FONT }}
                onClick={props.actionSave}
                type="primary"
              >
                Salvar
              </Button>
            </Space>
          )
        }
      >
        {props.body}
      </Drawer>
    </>
  );
};
export default DrawerApp;
