import { Col, Divider, Row, Segmented } from "antd";
import React, { useState } from "react";

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">
      {title}: {content}
    </p>
  </div>
);

const MalePoint = () => {
  return (
    <div>
      <p className="site-description-item-profile-p">Tabela de Pontuações</p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Nível Unique" content="27 a 30 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Nível Ouro" content="22 a 26 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Nível Prata" content="16 a 21 pontos " />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Nível bronze" content="6 a 15 pontos" />
        </Col>
      </Row>
      <Divider />

      <p className="site-description-item-profile-p">
        Sprint 20m (ida e volta de 10m) - Cod Sprint
      </p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Ruim (acima de 5s)" content="1 ponto" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Moderado (entre 4,7 e 4,9s)"
            content="2 pontos"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Bom (entre 4,4 e 4,6s)" content="3 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Muito Bom (entre 4,1 e 4,3s)"
            content="4 pontos"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Excelente (abaixo de 4,1s)"
            content="5 pontos"
          />
        </Col>
      </Row>

      <Divider />
      <p className="site-description-item-profile-p">
        Sprint 10m - Sprint Linear
      </p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Ruim (acima de 1,75s)" content="1 ponto" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Moderado (entre 1,71 e 1,75s)"
            content="2 pontos"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Bom (entre 1,66 e 1,70s)"
            content="3 pontos"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Muito Bom (entre 1,60 e 1,65s)"
            content="4 pontos"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Excelente (abaixo de 1,60s)"
            content="5 pontos"
          />
        </Col>
      </Row>

      <Divider />
      <p className="site-description-item-profile-p">
        Countermovement Jump (CMJ)
      </p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Abaixo de 46 cm" content="1 ponto" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 46 e 50 cm" content="2 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 51 e 55 cm" content="3 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 56 e 60 cm" content="4 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Acima de 60 cm" content="5 pontos" />
        </Col>
      </Row>

      <Divider />
      <p className="site-description-item-profile-p">Squat Jump (SJ)</p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Abaixo de 41 cm" content="1 ponto" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 41 e 45 cm" content="2 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 46 e 50 cm" content="3 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 51 e 55 cm" content="4 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Acima de 55 cm" content="5 pontos" />
        </Col>
      </Row>

      <Divider />
      <p className="site-description-item-profile-p">Força Relativa</p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Abaixo de 1,0" content="1 ponto" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 1,0 e 1,25" content="2 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 1,26 e 1,5" content="3 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 1,51 e 2,0" content="4 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Acima de 2,0" content="5 pontos" />
        </Col>
      </Row>

      <Divider />
      <p className="site-description-item-profile-p">Drop Jump (DJ)</p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Abaixo de 1,5" content="1 ponto" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 1,5 e 2,0" content="2 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 2,0 e 2,5" content="3 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 2,5 e 3,0" content="4 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Acima de 3,0" content="5 pontos" />
        </Col>
      </Row>
      <Divider />
      <p className="site-description-item-profile-p">Precisão</p>

      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Abaixo de 0,96"
            content="Ruim (Valores de força relativa podem estar imprecisos)"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 0,96 e 0,97" content="Moderado" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 0,98 e 1" content="Excelente" />
        </Col>
      </Row>
    </div>
  );
};
const FemalePoint = () => {
  return (
    <div>
      <p className="site-description-item-profile-p">Tabela de Pontuações</p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Nível Unique" content="27 a 30 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Nível Ouro" content="22 a 26 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Nível Prata" content="16 a 21 pontos " />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Nível bronze" content="6 a 15 pontos" />
        </Col>
      </Row>
      <Divider />
      <p className="site-description-item-profile-p">
        Sprint 20m (ida e volta de 10m) - Cod Sprint
      </p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Ruim (acima de 5.1s)" content="1 ponto" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Moderado (entre 4,8 e 5,1s)"
            content="2 pontos"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Bom (entre 4,5 e 4,8s)" content="3 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Muito Bom (entre 4,2 e 4,5s)"
            content="4 pontos"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Excelente (abaixo de 4,2s)"
            content="5 pontos"
          />
        </Col>
      </Row>

      <Divider />
      <p className="site-description-item-profile-p">
        Sprint 10m - Sprint Linear
      </p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Ruim (acima de 1,90s)" content="1 ponto" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Moderado (entre 1,85 e 1,90s)"
            content="2 pontos"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Bom (entre 1,80 e 1,85s)"
            content="3 pontos"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Muito Bom (entre 1,75 e 1,80s)"
            content="4 pontos"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Excelente (abaixo de 1,75s)"
            content="5 pontos"
          />
        </Col>
      </Row>

      <Divider />
      <p className="site-description-item-profile-p">
        Countermovement Jump (CMJ)
      </p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Abaixo de 41 cm" content="1 ponto" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 41 e 45 cm" content="2 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 46 e 50 cm" content="3 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 51 e 55 cm" content="4 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Acima de 55 cm" content="5 pontos" />
        </Col>
      </Row>

      <Divider />
      <p className="site-description-item-profile-p">Squat Jump (SJ)</p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Abaixo de 36 cm" content="1 ponto" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 36 e 40 cm" content="2 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 41 e 45 cm" content="3 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 46 e 50 cm" content="4 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Acima de 50 cm" content="5 pontos" />
        </Col>
      </Row>

      <Divider />
      <p className="site-description-item-profile-p">Força Relativa</p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Abaixo de 1,2" content="1 ponto" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 1,2 e 1,4" content="2 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 1,4 e 1,6" content="3 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 1,6 e 1,8" content="4 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Acima de 1,8" content="5 pontos" />
        </Col>
      </Row>

      <Divider />
      <p className="site-description-item-profile-p">Drop Jump (DJ)</p>

      <Row>
        <Col span={24}>
          <DescriptionItem title="Abaixo de 1,0" content="1 ponto" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 1,0 e 1,5" content="2 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 1,5 e 2,0" content="3 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 2,0 e 2,5" content="4 pontos" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Acima de 2,5" content="5 pontos" />
        </Col>
      </Row>
      <Divider />
      <p className="site-description-item-profile-p">Precisão</p>

      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Abaixo de 0,96"
            content="Ruim (Valores de força relativa podem estar imprecisos)"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 0,96 e 0,97" content="Moderado" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem title="Entre 0,98 e 1" content="Excelente" />
        </Col>
      </Row>
    </div>
  );
};

const FormUserResultPoint = () => {
  const [genderPoint, setGenderPoint] = useState("Masculino");

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Segmented
          options={["Masculino", "Feminino"]}
          onChange={(value) => {
            setGenderPoint(value); // string
          }}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        {genderPoint == "Masculino" && <MalePoint />}
        {genderPoint == "Feminino" && <FemalePoint />}
      </div>
    </div>
  );
};

export default FormUserResultPoint;
