import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import MainApp from "views/main-app";

import Login from "views/login/login.js";
import PrivateRoute from "util/private-route.js";
import "./scss/style.scss";
import {
  ROLE_ADMIN,
  USER_STORAGE,
  ROLE_USER,
  ROLE_USER_ADMIN,
} from "./constants";
import UsersClient from "views/client/users/users-client";
import Expenses from "views/client/expenses/expenses";
import { useSelector } from "react-redux";
import { selectRole } from "reducer/login/login-user";
import Page404 from "views/404/Page404";
import BillsPay from "views/client/bills-pay/bills-pay";
import BillsReceive from "views/client/bills-receive/bills-receive";
import AccountingIcome from "views/client/accounting/income";
import Managerial from "views/client/managerial/managerial";
import Revenues from "views/client/revenues/revenues";
import CostCenters from "views/client/cost-center/cost-centers";
import ReviewsClient from "views/client/reviews/reviews-client";
import ReviewsClientUser from "views/client/reviews/reviews-client-user";
import RankingClient from "views/client/ranking/ranking-client";
import LoadControlClient from "views/client/load-control/load-control-client";

const RouterApp = (props) => {
  const [roleRedux, setRoleRedux] = useState(useSelector(selectRole));

  useEffect(() => {
    const localStorageItem = localStorage.getItem(USER_STORAGE);
    if (localStorageItem != null) {
      const { role } = JSON.parse(localStorageItem);
      if (roleRedux == "" || role != roleRedux) {
        setRoleRedux(role);
      }
    }
  }, []);

  function getNavegateApp() {
    let navegateApp =
      null == roleRedux || "" == roleRedux
        ? "/login"
        : "/app/client/managerial";

    return navegateApp;
  }
  setTimeout(() => {
    const localStorageItem = localStorage.getItem(USER_STORAGE);

    if (localStorageItem != null) {
      const { role } = JSON.parse(localStorageItem);
      if (roleRedux == "" || role != roleRedux) {
        setRoleRedux(role);
      }
    }
  }, 1000);
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/app"
            element={<Navigate to={getNavegateApp()} replace />}
          />

          <Route
            path="/app/client"
            element={<Navigate to={getNavegateApp()} replace />}
          />
          <Route exact path="/app" element={<PrivateRoute />}>
            <Route
              path="/app/client/users"
              element={<MainApp component={<UsersClient />} />}
            />

            <Route
              path="/app/client/revenues"
              element={<MainApp component={<Revenues />} />}
            />
            <Route
              path="/app/client/load-control"
              element={<MainApp component={<LoadControlClient />} />}
            />
             <Route
              path="/app/client/ranking"
              element={<MainApp component={<RankingClient />} />}
            />

            <Route
              path="/app/client/reviews"
              element={
                <MainApp
                  component={
                    roleRedux == ROLE_USER ? (
                      <ReviewsClientUser />
                    ) : (
                      <ReviewsClient />
                    )
                  }
                />
              }
            />

            <Route
              path="/app/client/bills-pay"
              element={<MainApp component={<BillsPay />} />}
            />
            <Route
              path="/app/client/bills-receive"
              element={<MainApp component={<BillsReceive />} />}
            />
            <Route
              path="/app/client/accounting-income"
              element={<MainApp component={<AccountingIcome />} />}
            />
            <Route
              path="/app/client/accounting-cash-flow"
              element={<MainApp component={<AccountingIcome />} />}
            />
            <Route
              path="/app/client/accounting"
              element={<MainApp component={<AccountingIcome />} />}
            />
            <Route
              path="/app/client/accounting-balance"
              element={<MainApp component={<AccountingIcome />} />}
            />
            <Route
              path="/app/client/managerial"
              element={<MainApp component={<Managerial />} />}
            />

            <Route
              path="/app/client/cost-center"
              element={<MainApp component={<CostCenters />} />}
            />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default RouterApp;
