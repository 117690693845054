import React, { useEffect, useState } from "react";

import {
  Row,
  Input,
  Typography,
  Form,
  Col,
  DatePicker,
  InputNumber,
  Divider,
  Avatar,
  Select,
  Space,
  Card,
  Table,
  Spin,
} from "antd";
import { Medal, UserRound } from "lucide-react";

import { FONT } from "constants/index";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ColumnsUserRecord } from "./columns-table";
dayjs.extend(customParseFormat);

const { Text } = Typography;
const dateFormat = "DD/MM/YYYY";

const FormNewRecord = (props) => {
  const [data, setDataDrawer] = useState([]);
  const [imgAvatar, setImgAvatar] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    setImgAvatar(props.imgAvatar);
    setLoadingData(props.loading);
    setDataDrawer(props.dataDrawer);
  }, [props]);
  return (
    <>
      <Spin spinning={loadingData}>
        <Form ref={props.formRef} layout="vertical">
          <Row
            gutter={16}
            style={{ textAlign: "center", marginBottom: "30px" }}
          >
            <Col span={24}>
              <Avatar
                src={imgAvatar}
                icon={<UserRound size={120} />}
                size={120}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label={<span style={{ fontFamily: FONT }}>Aluno</span>}
              >
                <Input disabled={true} style={{ fontFamily: FONT }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="date"
                label={<span style={{ fontFamily: FONT }}>Data</span>}
                rules={[
                  {
                    required: true,
                    message: "É necessário informar a data da avaliação",
                  },
                ]}
              >
                <DatePicker
                  style={{ fontFamily: FONT, width: "100%" }}
                  placeholder={["", ""]}
                  format={dateFormat}
                  suffixIcon={null}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="typeRecord"
                label="Tipo"
                rules={[
                  {
                    required: true,
                    message: "É necessário informar o tipo do recorde",
                  },
                ]}
              >
                <Select allowClear style={{ fontFamily: FONT, width: "100%" }}>
                  <>
                    <Option value="SPRINT_LINEAR">Sprint Linear</Option>
                    <Option value="COD_SPRINT">Cod Sprint</Option>
                  </>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="record"
                label={<span style={{ fontFamily: FONT }}>Resultado</span>}
                rules={[
                  {
                    required: true,
                    message: "É necessário informar o resultado do aluno",
                  },
                ]}
              >
                <InputNumber
                  decimalSeparator=","
                  style={{ fontFamily: FONT, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />

          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Space direction="vertical" size={"small"}>
              <Card
                id="card-table"
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Medal
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                        Histórico de Sprints
                      </Text>
                    </div>
                  </div>
                }
                bordered={false}
              >
                <Table
                  columns={ColumnsUserRecord()}
                  dataSource={data}
                  pagination={{ pageSize: 3, showSizeChanger: false }}
                  locale={{
                    emptyText: "Sem registro",
                  }}
                />
              </Card>
            </Space>
          </Row>
        </Form>
      </Spin>
    </>
  );
};
export default FormNewRecord;
