import React, { useEffect, useState, useCallback } from "react";
import {
  Row,
  Input,
  Typography,
  Form,
  Col,
  DatePicker,
  Divider,
  Avatar,
  Space,
  Card,
  Table,
  Spin,
  Tooltip,
} from "antd";
import {
  ArrowDownNarrowWide,
  ArrowUpNarrowWide,
  Award,
  Dumbbell,
  Gauge,
  UserRound,
  ArrowDownLeft,
  ArrowDownRight,
} from "lucide-react";

import { FONT } from "constants/index";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ColumnsUserResult } from "./columns-table";
import { chartBar } from "./reviews-drawer-charts";
dayjs.extend(customParseFormat);

const { Text } = Typography;
const dateFormat = "DD/MM/YYYY";

const FormUserResult = (props) => {
  const [data, setDataDrawer] = useState({ reviews: [] });
  const [imgAvatar, setImgAvatar] = useState(null);
  const formRef = React.useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const data = props.dataDrawer;
    data.reviews = data?.reviews?.map((obj) => ({
      ...obj,
      changeActual: changeActual,
      changeLast: changeLast,
    }));
    setDataDrawer(data);

    setImgAvatar(props.imgAvatar);
    formRef.current?.setFieldsValue({
      name: props.dataDrawer.name,
      reviewActual: props.dataDrawer?.actualReview?.reviewDate
        ? dayjs(props.dataDrawer?.actualReview?.reviewDate)
        : null,
      reviewComparation: props.dataDrawer?.lastReview?.reviewDate
        ? dayjs(props.dataDrawer?.lastReview?.reviewDate)
        : null,
    });
  }, [props, setLoading]);

  var colorAward = "#ffffff";

  if (data?.actualReview?.point <= 15) {
    colorAward = "#D97706";
  }
  if (data?.actualReview?.point >= 16 && data?.actualReview?.point <= 21) {
    colorAward = "#737373";
  }
  if (data?.actualReview?.point >= 22 && data?.actualReview?.point <= 26) {
    colorAward = "#FDE047";
  }
  if (data?.actualReview?.point >= 27) {
    colorAward = "#14B8A6";
  }
  function colorAwardCard(point) {
    var colorAward = "#ffffff";

    switch (point) {
      case 1:
        colorAward = "#D97706";
        break;
      case 2:
        colorAward = "#D97706";
        break;
      case 3:
        colorAward = "#737373";
        break;
      case 4:
        colorAward = "#FDE047";
        break;
      case 5:
        colorAward = "#14B8A6";
        break;
      default:
        break;
    }
    return colorAward;
  }
  const changeActual = function (row, data) {
    setLoading(true);
    setTimeout(() => {
      data.actualReview = row;
      formRef.current?.setFieldsValue({
        reviewActual: row.reviewDate ? dayjs(row.reviewDate) : null,
      });
      setDataDrawer(data);
      setLoading(false);
    }, 300);
  };

  const changeLast = function (row, data) {
    setLoading(true);
    setTimeout(() => {
      data.lastReview = row;
      formRef.current?.setFieldsValue({
        reviewComparation: row.reviewDate ? dayjs(row.reviewDate) : null,
      });

      setDataDrawer(data);
      setLoading(false);
    }, 300);
  };
  function levelRquad(point) {
    if (point >= 0.98 && point <= 1) {
      return " - Excelente";
    }
    if ((point >= 0.96 && point <= 0, 97)) {
      return " - Moderado";
    }
    if ((point <= 0, 95)) {
      return " - Ruim";
    }
    return "";
  }

  return (
    <>
      <Spin size="large" spinning={loading}>
        <Form ref={formRef} layout="vertical">
          <Row
            gutter={16}
            style={{ textAlign: "center", marginBottom: "30px" }}
          >
            <Col span={24}>
              <Avatar
                src={imgAvatar}
                icon={<UserRound size={120} />}
                size={120}
              />
              <Space direction="vertical">
                <Award size={30} color={colorAward} />
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: FONT,
                    fontWeight: 600,
                  }}
                >
                  {data?.actualReview?.point}
                </Text>
              </Space>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Space direction="vertical" size={"small"}>
              <Card
                id="card-table"
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Award
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                        Histórico de Resultados
                      </Text>
                    </div>
                  </div>
                }
                bordered={false}
              >
                <Table
                  columns={ColumnsUserResult(data)}
                  dataSource={data?.reviews}
                  pagination={{ pageSize: 2, showSizeChanger: false }}
                  locale={{
                    emptyText: "Sem registro",
                  }}
                />
              </Card>
            </Space>
          </Row>
          <Divider />

          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={outerWidth < 501 ? 24 : 8}>
              <Form.Item
                name="name"
                label={<span style={{ fontFamily: FONT }}>Aluno</span>}
              >
                <Input disabled={true} style={{ fontFamily: FONT }} />
              </Form.Item>
            </Col>

            <Col span={outerWidth < 501 ? 24 : 8}>
              <Form.Item
                name="reviewActual"
                label={
                  <span style={{ fontFamily: FONT }}>Avaliação Atual</span>
                }
                rules={[
                  {
                    required: true,
                    message: "É necessário informar a data da avaliação",
                  },
                ]}
              >
                <DatePicker
                  style={{ fontFamily: FONT, width: "100%" }}
                  placeholder={["", ""]}
                  format={dateFormat}
                  suffixIcon={null}
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col span={outerWidth < 501 ? 24 : 8}>
              <Form.Item
                name="reviewComparation"
                label={
                  <span style={{ fontFamily: FONT }}>
                    Avaliação de Comparação
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "É necessário informar a data da avaliação",
                  },
                ]}
              >
                <DatePicker
                  style={{ fontFamily: FONT, width: "100%" }}
                  placeholder={["", ""]}
                  format={dateFormat}
                  suffixIcon={null}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Gauge
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        VMP
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    {
                      load: data?.actualReview?.load1
                        ? data?.actualReview?.load1 - 10
                        : null,
                      vmpActual: null,
                      vmpComparation: null,
                    },

                    {
                      load: data?.actualReview?.load1,
                      vmpActual: data?.actualReview?.vmp1,
                      vmpComparation: data?.lastReview?.vmp1,
                    },
                    {
                      load: data?.actualReview?.load2,
                      vmpActual: data?.actualReview?.vmp2,
                      vmpComparation: data?.lastReview?.vmp2,
                    },
                    {
                      load: data?.actualReview?.load3,
                      vmpActual: data?.actualReview?.vmp3,
                      vmpComparation: data?.lastReview?.vmp3,
                    },
                    {
                      load: data?.actualReview?.load4,
                      vmpActual: data?.actualReview?.vmp4,
                      vmpComparation: data?.lastReview?.vmp4,
                    },
                    {
                      load: data?.actualReview?.load5,
                      vmpActual: data?.actualReview?.vmp5,
                      vmpComparation: data?.lastReview?.vmp5,
                    },
                    {
                      load: data?.actualReview?.load6,
                      vmpActual: data?.actualReview?.vmp6,
                      vmpComparation: data?.lastReview?.vmp6,
                    },

                    {
                      load: data?.actualReview?.load6
                        ? data?.actualReview?.load6 + 10
                        : null,
                      vmpActual: null,
                      vmpComparation: null,
                    },
                  ],
                  "load",
                  "vmpActual",
                  "vmpComparation",
                  "vmp Atual",
                  "vmp Comparação",
                  10
                )}
              </Card>
            </Col>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Dumbbell
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Força Relativa
                      </Text>
                    </div>
                    <div>
                      <Award
                        size={25}
                        color={colorAwardCard(
                          data?.actualReview?.relativeStrengthPoint
                        )}
                      />
                      <Text
                        style={{
                          fontSize: "30px",
                          fontFamily: FONT,
                          fontWeight: 600,
                        }}
                      >
                        {data?.actualReview?.relativeStrengthPoint}
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump: null,
                      jumpActual: data?.actualReview?.relativeStrength,
                      jumpComparation: data?.lastReview?.relativeStrength,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "Força Relativa Atual",
                  "Força Relativa Comparação",
                  10
                )}
              </Card>
              <Row gutter={16} style={{ marginTop: "15px" }}>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                    }}
                  >
                    Força Absoluta Atual:
                  </Text>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                      fontWeight: "bold",
                    }}
                  >
                    {data?.actualReview?.absoluteStrength &&
                      " " + data?.actualReview?.absoluteStrength + "  KG"}
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                    }}
                  >
                    Precisão Atual:
                  </Text>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                      fontWeight: "bold",
                    }}
                  >
                    {data?.actualReview?.rquad &&
                      " " +
                        data?.actualReview?.rquad +
                        levelRquad(data?.actualReview?.rquad)}
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                    }}
                  >
                    Força Absoluta Comparação:
                  </Text>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                      fontWeight: "bold",
                    }}
                  >
                    {data?.lastReview?.absoluteStrength &&
                      " " + data?.lastReview?.absoluteStrength + "  KG"}
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                    }}
                  >
                    Precisão Comparação:
                  </Text>
                  <Text
                    style={{
                      fontSize: "15px",
                      fontFamily: FONT,
                      fontWeight: "bold",
                    }}
                  >
                    {data?.lastReview?.rquad &&
                      " " +
                        data?.lastReview?.rquad +
                        levelRquad(data?.lastReview?.rquad)}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider />
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col span={outerWidth < 501 ? 24 : 8} style={{ marginTop: "10px" }}>
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <ArrowUpNarrowWide
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Counter Movement Jump - CMJ
                      </Text>
                    </div>
                    <div>
                      <Award
                        size={25}
                        color={colorAwardCard(data?.actualReview?.cmjPoint)}
                      />
                      <Text
                        style={{
                          fontSize: "30px",
                          fontFamily: FONT,
                          fontWeight: 600,
                        }}
                      >
                        {data?.actualReview?.cmjPoint}
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump: null,
                      jumpActual: data?.actualReview?.cmj,
                      jumpComparation: data?.lastReview?.cmj,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "CMJ Atual",
                  "CMJ Comparação",
                  10
                )}
              </Card>
            </Col>
            <Col span={outerWidth < 501 ? 24 : 8} style={{ marginTop: "10px" }}>
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <ArrowUpNarrowWide
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Squat Jump - SJ
                      </Text>
                    </div>
                    <div>
                      <Award
                        size={25}
                        color={colorAwardCard(data?.actualReview?.sjPoint)}
                      />
                      <Text
                        style={{
                          fontSize: "30px",
                          fontFamily: FONT,
                          fontWeight: 600,
                        }}
                      >
                        {data?.actualReview?.sjPoint}
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump: null,
                      jumpActual: data?.actualReview?.sj,
                      jumpComparation: data?.lastReview?.sj,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "SJ Atual",
                  "SJ Comparação",
                  10
                )}
              </Card>
            </Col>
            <Col span={outerWidth < 501 ? 24 : 8} style={{ marginTop: "10px" }}>
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <ArrowDownNarrowWide
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Drop Jump - DJ
                      </Text>
                    </div>
                    <div>
                      <Award
                        size={25}
                        color={colorAwardCard(data?.actualReview?.djPoint)}
                      />
                      <Text
                        style={{
                          fontSize: "30px",
                          fontFamily: FONT,
                          fontWeight: 600,
                        }}
                      >
                        {data?.actualReview?.djPoint}
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump: null,
                      jumpActual: data?.actualReview?.dj,
                      jumpComparation: data?.lastReview?.dj,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "DJ Atual",
                  "DJ Comparação",
                  10
                )}
              </Card>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16} style={{ marginTop: "15px" }}>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Gauge
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Sprint Linear
                      </Text>
                    </div>
                    <div>
                      <Award
                        size={25}
                        color={colorAwardCard(
                          data?.actualReview?.sprintLinearPoint
                        )}
                      />
                      <Text
                        style={{
                          fontSize: "30px",
                          fontFamily: FONT,
                          fontWeight: 600,
                        }}
                      >
                        {data?.actualReview?.sprintLinearPoint}
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump: null,
                      jumpActual: data?.actualReview?.sprintLinear,
                      jumpComparation: data?.lastReview?.sprintLinear,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "Sprint Linear Atual",
                  "Sprint Linear Comparação",
                  10
                )}
              </Card>
            </Col>
            <Col
              span={outerWidth < 501 ? 24 : 12}
              style={{ marginTop: "10px" }}
            >
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                      <Gauge
                        size={18}
                        style={{ marginBottom: "-4px", marginRight: "10px" }}
                      />
                      <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                        Cod Sprint
                      </Text>
                    </div>
                    <div>
                      <Award
                        size={25}
                        color={colorAwardCard(
                          data?.actualReview?.codSprintPoint
                        )}
                      />
                      <Text
                        style={{
                          fontSize: "30px",
                          fontFamily: FONT,
                          fontWeight: 600,
                        }}
                      >
                        {data?.actualReview?.codSprintPoint}
                      </Text>
                    </div>
                  </div>
                }
                style={{ width: "100%" }}
              >
                {chartBar(
                  [
                    { jump: null, jumpActual: null, jumpComparation: null },

                    {
                      jump: null,
                      jumpActual: data?.actualReview?.codSprint,
                      jumpComparation: data?.lastReview?.codSprint,
                    },

                    { jump: null, jumpActual: null, jumpComparation: null },
                  ],
                  "jump",
                  "jumpActual",
                  "jumpComparation",
                  "Cod Sprint Atual",
                  "Cod Sprint Comparação",
                  10
                )}
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default FormUserResult;
