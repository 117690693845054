import { Button, Result } from "antd";
import { ROLE_ADMIN, ROLE_USER, USER_STORAGE } from "constants/index";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectRole } from "reducer/login/login-user";

const Page404 = () => {
  const navigate = useNavigate();

  const [roleRedux, setRoleRedux] = useState(useSelector(selectRole));

  useEffect(() => {
    const localStorageItem = localStorage.getItem(USER_STORAGE);
    if (localStorageItem != null && roleRedux == "") {
      const { role } = JSON.parse(localStorageItem);
      setRoleRedux(role);
    }
  }, []);

  function getNavegateApp() {
    let navegateApp =
      null == roleRedux || "" == roleRedux
        ? "/login"
        : "/app/client/reviews";

    return navegateApp;
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="Desculpe, a página que você visitou não existe."
      extra={
        <Button type="primary" onClick={() => navigate(getNavegateApp())}>
          Voltar
        </Button>
      }
    />
  );
};
export default Page404;
