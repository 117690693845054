import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";

// Utils
import { getToken } from "util/auth";
import { AUTH } from "constants";

const PrivateRoute = () => {
  var auth = getToken() !== null;
  if (auth) {
    var token = getToken();
    token = token.replace("bearer ", "");
    const { exp, id, name, sub, role } = jwt_decode(token);
    if (Date.now() >= exp * 1000) {
      auth = false;
    }
  }
  return auth ? <Outlet /> : <Navigate to="/login" state={{ error: AUTH }} />;
};

export default PrivateRoute;
