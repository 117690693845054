import Services from "api/ProjectApiConsumer";

export function getCompanies() {
  return Services.companyService.getCompanies();
}
export function getAllBanks() {
  return Services.companyService.getAllBanks();
}

export function saveCompany(obj) {
  return Services.companyService.saveCompany(obj);
}
export function putCompany(obj) {
  return Services.companyService.putCompany(obj);
}
export function deleteCompany(id) {
  return Services.companyService.deleteCompany(undefined, undefined, id);
}

export function saveBank(obj) {
  return Services.companyService.saveBank(obj);
}
export function putBank(obj) {
  return Services.companyService.putBank(obj);
}

export function deleteCompanyBank(id) {
  return Services.companyService.deleteCompanyBank(undefined, undefined, id);
}

export function getCompanyCategories() {
  return Services.companyService.getCompanyCategories();
}

export function saveCompanyCategories(obj) {
  return Services.companyService.saveCompanyCategories(obj);
}
export function putCompanyCategories(obj) {
  return Services.companyService.putCompanyCategories(obj);
}

export function deleteCompanyCategories(id) {
  return Services.companyService.deleteCompanyCategories(
    undefined,
    undefined,
    id
  );
}

export function getCompanyMappingCashFlow() {
  return Services.companyService.getCompanyMappingCashFlow();
}

export function saveCompanyMappingCashFlow(obj) {
  return Services.companyService.saveCompanyMappingCashFlow(obj);
}

export function getCompanyCategoriesByCompanyId(id) {
  return Services.companyService.getCompanyCategoriesByCompanyId(
    undefined,
    undefined,
    id
  );
}
export function deleteCompanyMappingCashFlow(id) {
  return Services.companyService.deleteCompanyMappingCashFlow(
    undefined,
    undefined,
    id
  );
}
export function deleteCompanyMappingCashFlowCategory(id) {
  return Services.companyService.deleteCompanyMappingCashFlowCategory(
    undefined,
    undefined,
    id
  );
}
export function putCompanyMappingCashFlow(obj) {
  return Services.companyService.putCompanyMappingCashFlow(obj);
}

export function getCompanyMappingIncome() {
  return Services.companyService.getCompanyMappingIncome();
}

export function saveCompanyMappingIncome(obj) {
  return Services.companyService.saveCompanyMappingIncome(obj);
}

export function deleteCompanyMappingIncome(id) {
  return Services.companyService.deleteCompanyMappingIncome(
    undefined,
    undefined,
    id
  );
}
export function deleteCompanyMappingIncomeCategory(id) {
  return Services.companyService.deleteCompanyMappingIncomeCategory(
    undefined,
    undefined,
    id
  );
}
export function putCompanyMappingIncome(obj) {
  return Services.companyService.putCompanyMappingIncome(obj);
}

export function getIncome(obj) {
  return Services.companyService.getIncome(obj);
}
export function getIncomeV2(obj) {
  return Services.incomeService.getIncomeV2(obj);
}
export function getCashFlow(obj) {
  return Services.companyService.getCashFlow(obj);
}

export function getCompanyGroupByCompanyId(id) {
  return Services.companyService.getCompanyGroupByCompanyId(
    undefined,
    undefined,
    id
  );
}
export function getStatus() {
  return Services.companyService.getStatus();
}

export function integrationImport(id) {
  return Services.companyService.integrationImport(undefined, undefined, id);
}

export function getCostCenters(obj) {
  return Services.companyService.getCostCenters(undefined, obj);
}
export function integrationImportPlan(id) {
  return Services.companyService.integrationImportPlan(undefined, undefined, id);
}
export function getCashFlowByFilter(obj) {
  return Services.cashFlowService.getCashFlowByFilter(obj);
}
