import { Result } from "antd";
const AccountingIcome = () => {
  return (
    <div>
      <Result
        status="403"
        subTitle="Desculpe, você não tem acesso a esse módulo."
      />
    </div>
  );
};
export default AccountingIcome;
