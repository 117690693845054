import { items } from "fusioncharts";
import { formatCurrency } from "util/format";

export const mountChartProfit = (profit, profitPerCent) => {
  let dataSource = {
    chart: {
      subcaption: formatCurrency(profit),
      lowerlimit: "0",
      upperlimit: "100",
      showvalue: "1",
      numbersuffix: "%",
      theme: "gammel",
    },
    colorrange: {
      color: [
        {
          minvalue: "0",
          maxvalue: profitPerCent,
          code: "#2A8DEB",
        },
        {
          minvalue: profitPerCent,
          maxvalue: "100",
          code: "#BCBCBC",
        },
      ],
    },
    dials: {
      dial: [
        {
          value: profitPerCent,
        },
      ],
    },
  };
  return dataSource;
};

export const mountChartPie = (data, title, color, colorChart) => {
  let dataSource = {
    chart: {
      bgColor: color,
      showpercentvalues: "1",
      aligncaptionwithcanvas: "0",
      showLabels: "0",
      captionpadding: "0",
      showlegend: "1",
      exportEnabled: "1",
      legendposition: "right",
      exportFileName: title,
      numberPrefix: "R$ ",
      //Disabling number scale compression
      formatNumberScale: "0",
      //Defining custom decimal separator
      decimalSeparator: ",",
      //Defining custom thousand separator
      thousandSeparator: ".",
      legendscrollbgColor: "#cccccc",
      legendscrollbarColor: "#999999",
      legendNumColumns: "1",
      theme: "gammel",
      legendWidth: "150",
    },
    data: mountDataPie(data),
  };
  return dataSource;
};
const mountDataPie = (data) => {
  const Arrylabel = [];
  if (data) {
    data.forEach((data) => {
      Arrylabel.push({
        label: data.category,
        value: data.amount,
      });
    });
  }
  return Arrylabel;
};

export const mountChartProfitBar = (res, title, showBudget) => {
  let dataSource = {
    chart: {
      theme: "gammel",
      adjustdiv: "0",
      pYAxisName: "Lançamentos",
      sYAxisName: "Lucro",
      drawcustomlegendicon: "1",
      plottooltext: "$label, $seriesname: $dataValue",
      palettecolors: "#ED5E80,#2A8DEB",
      legendposition: "top",
      exportEnabled: "1",
      numberPrefix: "R$ ",
      //Disabling number scale compression
      formatNumberScale: "0",
      //Defining custom decimal separator
      decimalSeparator: ",",
      //Defining custom thousand separator
      thousandSeparator: ".",
      exportFileName: title,
    },

    categories: [
      {
        category: mountLabel(res),
        fontSize: "15px",
      },
    ],

    dataset: [
      {
        seriesname: "Realizado",
        data: mountData(res, "DONE"),
      },
      {
        seriesname: showBudget ? "Orçado" : "Ano Anterior",
        data: mountData(res, "LAST", 0, showBudget),
      },
    ],
  };
  return dataSource;
};

const mountLabel = (data) => {
  const Arrylabel = [];
  if (data) {
    data.forEach((data) => {
      Arrylabel.push({
        label: data.monthData,
      });
    });
  }
  return Arrylabel;
};

const mountData = (data, type, balance, showBudget) => {
  const Arrylabel = [];

  switch (type) {
    case "PAID":
      if (data) {
        data.forEach((data) => {
          Arrylabel.push({
            value: data.expense,
          });
        });
      }
      break;
    case "RECEIVE":
      if (data) {
        data.forEach((data) => {
          Arrylabel.push({
            value: data.revenue,
          });
        });
      }
      break;
    case "PROFIT":
      if (data) {
        data.forEach((data) => {
          Arrylabel.push({
            value: data.profit,
          });
        });
      }
      break;

    case "PAID_BALANCE":
      if (data) {
        data.forEach((data) => {
          Arrylabel.push({
            value: data.expense * -1,
          });
        });
      }
      break;

    case "BALANCE":
      if (data) {
        data.forEach((data) => {
          Arrylabel.push({
            value: balance,
          });
        });
      }
      break;

    case "DONE":
      if (data) {
        data.forEach((data) => {
          Arrylabel.push({
            value: data.amount,
          });
        });
      }
      break;
    case "LAST":
      if (data) {
        data.forEach((data) => {
          let amount = showBudget ? data.budget : data.monthLast;
          Arrylabel.push({
            value: amount,
          });
        });
      }
      break;
    default:
      break;
  }

  return Arrylabel;
};

export const mountChartLine = (res, title) => {
  let dataSource = {
    chart: {
      theme: "gammel",
      setadaptiveymin: "1",
      palettecolors: "#2A8DEB",
      legendposition: "top",
      exportEnabled: "1",
      showvalues: "1",
      numberPrefix: "R$ ",
      //Disabling number scale compression
      formatNumberScale: "0",
      //Defining custom decimal separator
      decimalSeparator: ",",
      //Defining custom thousand separator
      thousandSeparator: ".",
      exportFileName: title,
    },

    data: mountLabelValue(res),
  };
  return dataSource;
};

const mountLabelValue = (data) => {
  const Arrylabel = [];
  if (data) {
    data.forEach((data) => {
      Arrylabel.push({
        label: data.monthData,
        value: data.amount,
      });
    });
  }
  return Arrylabel;
};

export const mountWaterFall = (res, title) => {
  let dataSource = {
    chart: {
      theme: "gammel",
      numberPrefix: "R$ ",
      //Disabling number scale compression
      formatNumberScale: "0",
      //Defining custom decimal separator
      decimalSeparator: ",",
      //Defining custom thousand separator
      thousandSeparator: ".",
      showsumatend: "0",
      exportEnabled: "1",
      showvalues: "1",
      exportFileName: title,
    },

    data: mountLabelValueWaterFall(res),
  };
  return dataSource;
};

const mountLabelValueWaterFall = (data) => {
  const Arrylabel = [];
  if (data) {
    data.forEach((data) => {
      let color = data.amount > 0 ? "#2A8DEB" : "#2A8DEB";
      Arrylabel.push({
        label: data.category,
        value: data.amount,
        color: color,
      });
    });
  }
  return Arrylabel;
};

export const mountChartMultLine = (res, title, balance) => {
  let dataSource = {
    chart: {
      theme: "gammel",
      adjustdiv: "0",
      drawcustomlegendicon: "1",
      plottooltext: "$label, $seriesname: $dataValue",
      palettecolors: "#ED5E80,#2A8DEB,#FDCB50",
      legendposition: "top",
      exportEnabled: "1",
      numberPrefix: "R$ ",
      //Disabling number scale compression
      formatNumberScale: "0",
      //Defining custom decimal separator
      decimalSeparator: ",",
      //Defining custom thousand separator
      thousandSeparator: ".",
      exportFileName: title,
    },

    categories: [
      {
        category: mountLabel(res),
        fontSize: "15px",
      },
    ],

    dataset: [
      {
        seriesname: "Despesa",
        data: mountData(res, "PAID_BALANCE"),
      },
      {
        seriesname: "Receita",
        data: mountData(res, "RECEIVE"),
      },
      {
        seriesname: "Ponto de equilíbrio",
        data: mountData(res, "BALANCE", balance),
      },
    ],
  };
  return dataSource;
};
export const mountCharBar = (res, title) => {
  let dataSource = {
    chart: {
      theme: "gammel",
      plottooltext: "$label, $seriesname: $dataValue",
      palettecolors: "#2A8DEB",
      exportEnabled: "1",
      numberprefix: "R$",
      numberPrefix: "R$ ",
      //Disabling number scale compression
      formatNumberScale: "0",
      //Defining custom decimal separator
      decimalSeparator: ",",
      //Defining custom thousand separator
      thousandSeparator: ".",
      exportFileName: title,
    },
    data: mountDataBar(res),
  };
  return dataSource;
};
const mountDataBar = (data) => {
  const Arrylabel = [];
  if (data) {
    data.forEach((data) => {
      Arrylabel.push({
        label: data.datetrunc,
        value: data.amount,
      });
    });
  }
  return Arrylabel;
};
export const mountCharBarClient = (res, title) => {
  let dataSource = {
    chart: {
      theme: "gammel",
      plottooltext: "$label, $seriesname: $dataValue",
      palettecolors: "#2A8DEB",
      exportEnabled: "1",
      numberPrefix: "R$ ",
      //Disabling number scale compression
      formatNumberScale: "0",
      //Defining custom decimal separator
      decimalSeparator: ",",
      //Defining custom thousand separator
      thousandSeparator: ".",
      exportFileName: title,
    },
    data: mountDataBarClient(res),
  };
  return dataSource;
};
const mountDataBarClient = (data) => {
  const Arrylabel = [];
  if (data) {
    data.forEach((data) => {
      Arrylabel.push({
        label: data.customer,
        value: data.amount,
      });
    });
  }
  return Arrylabel;
};

export const mountCharBarCategory = (res, title) => {
  let dataSource = {
    chart: {
      theme: "gammel",
      plottooltext: "$label, $seriesname: $dataValue",
      palettecolors: "#2A8DEB",
      exportEnabled: "1",
      numberPrefix: "R$ ",
      //Disabling number scale compression
      formatNumberScale: "0",
      //Defining custom decimal separator
      decimalSeparator: ",",
      //Defining custom thousand separator
      thousandSeparator: ".",
      exportFileName: title,
    },
    data: mountDataBarCategory(res),
  };
  return dataSource;
};
const mountDataBarCategory = (data) => {
  const Arrylabel = [];
  if (data) {
    data.forEach((data) => {
      Arrylabel.push({
        label: data.category,
        value: data.amount,
      });
    });
  }
  return Arrylabel;
};

export function replaceMonth(data) {
  const newMonth = [];
  if (data) {
    data.forEach((dataItem) => {
      var name = dataItem.datetrunc.substring(0, 3);
      name = name.replace("JAN", "Janeiro");
      name = name.replace("FEB", "Fevereiro");
      name = name.replace("MAR", "Março");
      name = name.replace("APR", "Abril");
      name = name.replace("MAY", "Maio");
      name = name.replace("JUN", "Junho");
      name = name.replace("JUL", "Julho");
      name = name.replace("AUG", "Agosto");
      name = name.replace("SEP", "Setembro");
      name = name.replace("OCT", "Outubro");
      name = name.replace("NOV", "Novembro");
      name = name.replace("DEC", "Dezembro");
      let order = 0;
      switch (name) {
        case "Janeiro":
          order = 1;
          break;
        case "Fevereiro":
          order = 2;
          break;
        case "Março":
          order = 3;
          break;
        case "Abril":
          order = 4;
          break;
        case "Maio":
          order = 5;
          break;
        case "Junho":
          order = 6;
          break;
        case "Julho":
          order = 7;
          break;
        case "Agosto":
          order = 8;
          break;
        case "Setembro":
          order = 9;
          break;
        case "Outubro":
          order = 10;
          break;
        case "Novembro":
          order = 11;
          break;
        case "Dezembro":
          order = 12;
          break;

        default:
          break;
      }
      newMonth.push({
        name: name,
        order: order,
        amount: dataItem.sum,
        key: name.substring(0, 3),
      });
    });
  }
  let monthOrder = newMonth.sort(compareOrder);

  return monthOrder;
}

function compareOrder(a, b) {
  return a.order - b.order;
}
export const mountChartMultBar = (res, title, showBudget) => {
  let dataSource = {
    chart: {
      theme: "gammel",
      adjustdiv: "0",
      pYAxisName: "Lançamentos",
      sYAxisName: "Lucro",
      drawcustomlegendicon: "1",
      showvalues: "0",
      plottooltext: "$label, $seriesname: $dataValue",
      palettecolors: "#2A8DEB, #ED5E80",
      legendposition: "top",
      exportEnabled: "1",
      numberPrefix: "R$ ",
      //Disabling number scale compression
      formatNumberScale: "0",
      //Defining custom decimal separator
      decimalSeparator: ",",
      //Defining custom thousand separator
      thousandSeparator: ".",
      exportFileName: title,
    },

    categories: [
      {
        category: mountLabel(res),
        fontSize: "15px",
      },
    ],

    dataset: [
      {
        seriesname: "Realizado",
        data: mountData(res, "DONE"),
      },
      {
        seriesname: showBudget ? "Orçado" : "Ano Anterior",
        data: mountData(res, "LAST", 0, showBudget),
      },
    ],
  };
  return dataSource;
};

export const mountTreemap = (res, title) => {
  let total = 0;
  if (res) {
    total = res.reduce((accumulator, object) => {
      return accumulator + object["amount"];
    }, 0);
  }
  const dataSource = {
    chart: {
      algorithm: "squarified",
      slicingmode: "horizontal",
      plottooltext: "$label, $dataValue",
      theme: "gammel",
      exportEnabled: "1",
      numberprefix: "R$",
      exportFileName: title,
    },
    data: [{ label: title, value: total, data: mountDataTreemap(res) }],
    colorrange: {
      mapbypercent: "0",
      gradient: "1",
      minvalue: "0",
      code: "#a9d1f7",
      color: [
        {
          code: "#2A8DEB",
          maxvalue: total,
        },
      ],
    },
  };

  return dataSource;
};

const mountDataTreemap = (data) => {
  const Arrylabel = [];
  if (data) {
    data.forEach((data) => {
      Arrylabel.push({
        label: data.category,
        value: data.amount,
        svalue: data.amount,
      });
    });
  }
  return Arrylabel;
};

export const mountCharFunil = (res, title) => {
  let dataSource = {
    chart: {
      theme: "gammel",
      plottooltext: "$label, $dataValue",
      exportEnabled: "1",
      legendposition: "right",
      legendNumColumns: "1",
      numberprefix: "R$",
      legendWidth: 150,
      plotfillalpha: "70",
      streamlineddata: "0",
      showvalues: "1",
      exportFileName: title,
    },
    data: mountDataFunil(res),
  };
  return dataSource;
};
const mountDataFunil = (data) => {
  const Arrylabel = [];
  if (data) {
    data.forEach((data) => {
      Arrylabel.push({
        label: data.name,
        value: data.amount,
      });
    });
  }
  return Arrylabel;
};
