import { Button, Card, Form, Input, Modal, Spin, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import {
  notificationErroInternal,
  notificationInfo,
  notificationSucessMsg,
} from "util/api-utils";
import { login, generateCode, newPassword } from "services/login/login-service";
import { useNavigate, useLocation } from "react-router-dom";
import { NOT_FOND, AUTH } from "constants/index";
import { useDispatch } from "react-redux";
import { setLoginUser } from "reducer/login/login-user";
import moment from "moment";
import { AtSign, KeyRound } from "lucide-react";

const { Countdown } = Statistic;

const LOGO = require("images/login/unique-logo-verde-un.png");
const Login = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRefNewPassword = React.useRef(null);
  const [deadlineCode, setDeadlineCode] = useState(
    moment().add(5, "m").toDate()
  );
  const [showDeadLine, setShowDeadLine] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);

  useEffect(() => {
    if (props.error != null && props.error == NOT_FOND) {
      notificationErroInternal(
        "Página não encontrada, acesse pelo menu da plataforma!"
      );
    } else if (location.state != null && location.state.error == AUTH) {
      notificationErroInternal("Seu acesso expirou!");
    }
  }, []);

  function newCode() {
    const email = formRefNewPassword.current.getFieldValue("email");
    if (!email) {
      notificationErroInternal("É necessário preencher o campo e-mail");
      return;
    }
    setIsLoadingPassword(true);

    generateCode(email)
      .then(() => {
        setDeadlineCode(moment().add(5, "m").toDate());
        setShowDeadLine(true);
        notificationInfo("Foi enviado o código para seu e-mail");
        setIsLoadingPassword(false);
      })
      .catch((err) => {
        setIsLoadingPassword(false);

        if (err.message == "Failed to fetch") {
          notificationErroInternal(
            "Aconteceu um erro inesperado, por favor, tente mais tarde."
          );
          return;
        }
        const error = JSON.parse(err.message);
        if (error.status == 404) {
          notificationErroInternal("E-mail não encontrado");
        } else {
          notificationErroInternal(
            "Aconteceu um erro inesperado, por favor, tente mais tarde."
          );
        }
      });
  }

  function onFinishCode() {
    setShowDeadLine(false);
  }
  function onFinish(values) {
    setIsLoading(true);
    let request = {
      email: values.email,
      password: values.password,
    };
    login(request)
      .then((res) => {
        dispatch(setLoginUser());
        setTimeout(() => {
          setIsLoading(false);
          navigate("/app/client/reviews");
        }, 2000);
      })
      .catch((err) => {
        setIsLoading(false);
        notificationErroInternal("Email ou senha inválido.");
      });
  }
  const handleCancel = () => {
    formRefNewPassword.current?.resetFields();
    setIsModalOpen(false);
    setIsLoadingPassword(false);
  };

  const showModalBank = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsLoadingPassword(true);
    formRefNewPassword.current
      .validateFields()
      .then((values) => {
        if (values.password != values.confirmPassword) {
          notificationInfo("As senhas devem ser iguais!");
          setIsLoadingPassword(false);

          return;
        }

        const request = {
          code: values.code,
          email: values.email,
          password: values.password,
        };
        newPassword(request)
          .then(() => {
            notificationSucessMsg("Senha alterada com sucesso!");
            handleCancel();
          })
          .catch((err) => {
            setIsLoadingPassword(false);
            if (err.message == "Failed to fetch") {
              notificationErroInternal(
                "Aconteceu um erro inesperado, por favor, tente mais tarde."
              );
              return;
            }

            const error = JSON.parse(err.message);
            if (error.status == 404) {
              notificationErroInternal("E-mail não encontrado");
            } else if (error.status == 406) {
              notificationErroInternal("Código inválido");
            } else {
              notificationErroInternal(
                "Aconteceu um erro inesperado, por favor, tente mais tarde."
              );
            }
          });
      })
      .catch((errors) => {
        setIsLoadingPassword(false);
        console.log(errors);
      });
  };
  return (
    <div className="container-login">
      <div className="login-left"></div>
      <div className="login-right">
        <Card className="card-login">
          <img className="logo-login" src={LOGO} alt="Logo" />
          <div style={{ marginLeft: "20%" }}>
            <div className="text-welcome">
              <h2>Bem vindo de volta!</h2>
              <p style={{ color: "gray" }}>
                Preencha os dados abaixo e acesse sua conta.
              </p>
            </div>
            <div className="new-password" onClick={showModalBank}>
              <a>Esqueci minha senha</a>
            </div>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
              style={{ fontFamily: "'Montserrat', sans-serif" }}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "È necessário informar o e-mail!",
                  },
                ]}
                style={{
                  height: "40px",
                  marginBottom: "8%",
                  backgroundColor: "#FAFAFA",
                }}
              >
                <Input
                  prefix={<AtSign color="#BDBDBD" size={20} />}
                  placeholder="E-mail"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "È necessário informar a senha",
                  },
                ]}
                style={{
                  height: "40px",
                  marginBottom: "8%",
                  backgroundColor: "#FAFAFA",
                }}
              >
                <Input.Password
                  prefix={<KeyRound color="#BDBDBD" size={15} />}
                  placeholder="Senha"
                />
              </Form.Item>

              <Form.Item className="button-login">
                <Button
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "6px",
                    backgroundColor: "#1677FF",
                    fontWeight: 700,
                    fontFamily: "'Montserrat', sans-serif",
                  }}
                >
                  Acessar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </div>
      <Modal
        className="modal-password"
        style={{ textAlign: "center" }}
        title="Nova Senha"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoadingPassword}
            onClick={handleOk}
          >
            Salvar
          </Button>,
        ]}
      >
        <Form ref={formRefNewPassword} style={{ maxWidth: 600 }}>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              { required: true, message: "É necessário informar o e-mail" },
            ]}
          >
            <Input style={{ width: "93%", marginLeft: "15px" }} />
          </Form.Item>
          <div style={{ marginTop: "-20px", display: "flex" }}>
            {showDeadLine && (
              <div style={{ marginLeft: "90%" }}>
                <Countdown
                  value={deadlineCode}
                  onFinish={onFinishCode}
                  format="mm:ss"
                />
              </div>
            )}
            {!showDeadLine && (
              <div
                style={{ marginLeft: "75%" }}
                onClick={!isLoadingPassword ? newCode : () => console.log()}
              >
                <a>Solicitar Código</a>
              </div>
            )}
          </div>
          <Form.Item
            name="code"
            label="Código"
            rules={[
              { required: true, message: "É necessário informar o código" },
            ]}
          >
            <Input style={{ width: "93%", marginLeft: "15px" }} />
          </Form.Item>
          <Form.Item
            label="Senha"
            name="password"
            rules={[
              {
                required: true,
                message: "È necessário informar a senha",
              },
            ]}
          >
            <Input.Password style={{ width: "93%", marginLeft: "15px" }} />
          </Form.Item>
          <Form.Item
            label="Confirmar Senha"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "È necessário informar a confirmação de senha",
              },
            ]}
          >
            <Input.Password style={{ width: "93%", marginLeft: "15px" }} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Login;
