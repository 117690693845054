import React, { useEffect, useState } from "react";
import {
  Spin,
  Card,
  Table,
  Modal,
  Form,
  DatePicker,
  Button,
  Row,
  Select,
  Col,
} from "antd";

import ColumnsBillsPay from "./columns-table";
import ColumnsFooterBillsPay from "./columns-footer";
import {
  getBanks,
  getCashFlowByType,
} from "services/dashboard/dashboard-service";
import { notificationErroInternal } from "util/api-utils";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.gammel";
import ReactFC from "react-fusioncharts";
import ReactFusioncharts from "react-fusioncharts";
import {
  mountChartPaid,
  mountDataColumnAndLineCashFlow,
  mountDataStackCashFlow,
} from "./func-chart";
import dayjs from "dayjs";
import moment from "moment";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getStatus } from "services/client/client-service";
dayjs.extend(customParseFormat);

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);
FusionCharts.options["license"] = false;
const dateFormat = "DD/MM/YYYY";

const { info } = Modal;

const BillsPay = () => {
  const [loadingPaid, setLoadingPaid] = useState(true);
  const [filterStartDate, setStartDate] = useState(moment().format(dateFormat));
  const [filterEndDate, setEndDate] = useState(moment().format(dateFormat));
  const [banks, setBanks] = useState([]);
  const [filterBanks, setFilterBanks] = useState([]);
  const [status, setStatus] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const formRef = React.useRef(null);

  const [dataPaid, setDataPaid] = useState({
    amout: 0,
    data: [],
  });

  useEffect(() => {
    getStatus()
      .then((res) => {
        let selectStatus = res
          .filter((item) => item.name != "Cancelado")
          .map((obj) => ({
            value: obj.name,
            label: obj.name,
          }));
        let resIdStatus = res
          .filter((item) => item.name != "Cancelado")
          .map((item) => item.name);

        setFilterStatus(resIdStatus);

        setStatus(selectStatus);
        getBanks().then((res) => {
          let selectBank = res.map((obj) => ({
            value: obj.codeAccount,
            label: obj.description,
          }));
          selectBank.push({ value: "WITHOUT_BANK", label: "Em Branco" });

          let resIdBank = res.map((item) => item.codeAccount);
          resIdBank.push("WITHOUT_BANK");

          setFilterBanks(resIdBank);
          setBanks(selectBank);
          formRef.current?.setFieldsValue({
            banks: resIdBank,
            status: resIdStatus,
          });
          getDataPaid(resIdStatus, resIdBank);
        });
      })

      .catch((err) => {
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }, []);

  function getDataPaid(filterStatus, filterBanks) {
    const filter = {
      start: filterStartDate,
      end: filterEndDate,
      type: "CONTA_A_PAGAR",
      banks: filterBanks,
      status: filterStatus,
    };
    getCashFlowByType(filter)
      .then((res) => {
        setLoadingPaid(false);
        setDataPaid(res);
      })
      .catch((err) => {
        setLoadingPaid(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  const refreshData = () => {
    setLoadingPaid(true);
    getDataPaid(filterStatus, filterBanks);
  };

  const setFilterStartDate = (e) => {
    if (e == null) {
      setStartDate(moment().format(dateFormat));
    } else {
      setStartDate(e.format(dateFormat));
    }
  };
  const setFilterEndDate = (e) => {
    if (e == null) {
      setEndDate(moment().format(dateFormat));
    } else {
      setEndDate(e.format(dateFormat));
    }
  };

  const getPaid = () => {
    return (
      <Table
        columns={ColumnsBillsPay}
        dataSource={dataPaid.data}
        pagination={{
          pageSize: 5,
        }}
        locale={{
          emptyText: "Sem registro",
        }}
        footer={() => (
          <Table
            showHeader={false}
            columns={ColumnsFooterBillsPay}
            dataSource={[dataPaid]}
            pagination={false}
          />
        )}
      />
    );
  };

  function showConfirm(title, div) {
    info({
      title: <div style={{ textAlign: "center" }}>{title}</div>,
      icon: null,
      content: div,
      width: "100%",
      centered: true,
      getContainer() {},
      onOk() {},
    });
  }

  const divPaid = getPaid();
  const setFilterBank = (e) => {
    if (e.length == 0) {
      let selectBank = banks.map((obj) => obj.value);
      setFilterBanks(selectBank);
    } else {
      setFilterBanks(e);
    }
  };
  const changeFilterStatus = (e) => {
    if (e.length == 0) {
      let statusFilter = status.map((obj) => obj.value);
      setFilterStatus(statusFilter);
    } else {
      setFilterStatus(e);
    }
  };
  const selectPropsBank = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    options: banks,
    onChange: (newValue) => {
      setFilterBank(newValue);
    },
    placeholder: "Selecione o banco",
    maxTagCount: "responsive",
  };

  const selectPropsStatus = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    options: status,
    onChange: (newValue) => {
      changeFilterStatus(newValue);
    },
    placeholder: "Selecione o status",
    maxTagCount: "responsive",
  };
  return (
    <div className="container-cash-flow-client">
      <Row gutter={24} style={{ padding: "10px" }}>
        <Col span={24}>
          <Card
            title={"Filtros"}
            bordered={false}
            extra={
              <Button type="primary" onClick={refreshData}>
                Filtrar
              </Button>
            }
          >
            <Form ref={formRef} layout={"horizontal"}>
              <Form.Item
                name="startDate"
                style={{ width: "50%" }}
                label="Dia Inicial"
              >
                <DatePicker
                  defaultValue={dayjs(filterStartDate, dateFormat)}
                  format={dateFormat}
                  allowClear={false}
                  style={{ width: "100%" }}
                  placeholder="Selecione o dia inicial"
                  onChange={setFilterStartDate}
                />
              </Form.Item>

              <Form.Item
                name="endDate"
                style={{ width: "50%" }}
                label="Dia Final"
              >
                <DatePicker
                  defaultValue={dayjs(filterEndDate, dateFormat)}
                  format={dateFormat}
                  style={{ width: "100%" }}
                  allowClear={false}
                  placeholder="Selecione o dia final"
                  onChange={setFilterEndDate}
                />
              </Form.Item>
              <Form.Item style={{ width: "50%" }} name="banks" label="Bancos">
                <Select {...selectPropsBank} />
              </Form.Item>
              <Form.Item style={{ width: "50%" }} name="status" label="Status">
                <Select {...selectPropsStatus} />
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>

      <div className="col-cash-flow-client">
        <Card
          title="Pagamentos"
          bordered={false}
          extra={
            <a onClick={() => showConfirm("Pagamentos", divPaid)}>Expandir</a>
          }
        >
          <Spin spinning={loadingPaid} size={"large"}>
            {divPaid}
          </Spin>
        </Card>
      </div>
      <Row gutter={24}>
        <Col span={24}>
          <div className="col-cash-flow-client">
            <Card bordered={true}>
              <ReactFusioncharts
                type="doughnut2d"
                id="chartPaid"
                width="100%"
                height="600%"
                dataFormat="JSON"
                dataSource={mountChartPaid(dataPaid.dataChart)}
              />
            </Card>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <div className="col-cash-flow-client">
            <Card bordered={true}>
              <ReactFusioncharts
                type="stackedcolumn2d"
                id="chartPaidStack"
                width="100%"
                height="600%"
                dataFormat="JSON"
                dataSource={mountDataStackCashFlow(
                  dataPaid.evolutionAll,
                  dataPaid.evolutionExpiring,
                  dataPaid.evolutionLate
                )}
              />
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default BillsPay;
