import React from "react";
import { Divider, Layout } from "antd";

import MenuApp from "components/menu-app";
import { setLogoutUser } from "reducer/login/login-user";
import { useDispatch } from "react-redux";
import Header from "./header";
import { USER_STORAGE, ACCESS_TOKEN } from "constants/index";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;

const MainApp = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let height = window.innerHeight;
  function logout() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(USER_STORAGE);
    dispatch(setLogoutUser());
    navigate("/login");
  }
  return (
    <Layout>
      <MenuApp />

      <Layout style={{ padding: "0" }}>
        <Header logout={logout} />
        <Divider style={{ margin: "0px", background: "#E0E0E0" }} />

        <Content
          style={{
            background: "#E0E2E7",
            padding: 24,
            margin: 0,
            minHeight: height,
            borderRadius: 4,
          }}
        >
          {props.component}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainApp;
