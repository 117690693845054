import moment from "moment";
import { formatCurrency } from "util/format";

const ColumnsBillsReceive = [
  {
    title: "Vencimento",
    dataIndex: "dueDate",
    render: (text) => {
      let date = moment(text).format("DD/MM/YYYY");
      return date;
    },
  },
  {
    title: "Cliente",
    dataIndex: "customer",
    textWrap: "word-break",
    ellipsis: true,
  },
  {
    title: "Categoria",
    dataIndex: "category",
    textWrap: "word-break",
    ellipsis: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    textWrap: "word-break",
    ellipsis: true,
  },
  {
    title: "Entrada",
    dataIndex: "amountPaid",
    render: (text) => {
      return formatCurrency(text);
    },
  },
];

export default ColumnsBillsReceive;
