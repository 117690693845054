export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ACCESS_TOKEN = "accessToken";
export const USER_STORAGE = "userStorage";
export const ROLE_USER = "USER";
export const ROLE_ADMIN = "ADMIN";
export const ROLE_USER_ADMIN = "USER_ADMIN";
export const NOT_FOND = "NOT_FOND";
export const AUTH = "AUTH";

export const API_BASE_URL = BASE_URL + "/api";
export const FONT = '"Montserrat", sans-serif';
