export const mountChartReceived = (data) => {
  let dataSource = {
    chart: {
      caption: "Valores por Categoria",
      showpercentvalues: "1",
      numberPrefix: "R$ ",
      //Disabling number scale compression
      formatNumberScale: "0",
      //Defining custom decimal separator
      decimalSeparator: ",",
      //Defining custom thousand separator
      thousandSeparator: ".",
      legendposition: "right",
      showborder: "0",
      showlegend: "1",
      plottooltext: "<b>$percentValue</b> é <b>$label</b>",
      theme: "gammel",
      legendscrollbgColor: "#cccccc",
      legendscrollbarColor: "#999999",
      legendNumColumns: "1",
      showLabels: "0",
      legendWidth: "300",
    },
    data: mountData(data),
  };
  return dataSource;
};

const mountData = (data) => {
  const Arrylabel = [];
  if (data && data.length > 0) {
    data.forEach((data) => {
      Arrylabel.push({
        label: data.category,
        value: data.amount,
      });
    });
  }
  return Arrylabel;
};

export const mountDataStackCashFlow = (all, expiring, late) => {
  let dataSource = {
    chart: {
      theme: "gammel",
      drawcustomlegendicon: "1",
      adjustdiv: "0",
      numberPrefix: "R$ ",
      //Disabling number scale compression
      formatNumberScale: "0",
      //Defining custom decimal separator
      decimalSeparator: ",",
      //Defining custom thousand separator
      thousandSeparator: ".",
      plottooltext: "$label, $seriesname: $dataValue",
      palettecolors: "#2A8DEB,#fff566,#ED5E80",
      legendposition: "top",
    },

    categories: [
      {
        category: [
          {
            label: "Janeiro",
          },
          {
            label: "Fevereiro",
          },
          {
            label: "Março",
          },
          {
            label: "Abril",
          },
          {
            label: "Maio",
          },
          {
            label: "Junho",
          },
          {
            label: "Julho",
          },
          {
            label: "Agosto",
          },
          {
            label: "Setembro",
          },
          {
            label: "Outubro",
          },
          {
            label: "Novembro",
          },
          {
            label: "Dezembro",
          },
        ],
        fontSize: "15px",
      },
    ],

    dataset: [
      {
        seriesname: "Recebido",
        data: mountDataStack(all),
      },
      {
        seriesname: "Em Aberto",
        data: mountDataStack(expiring),
      },
      {
        seriesname: "Atrasado",
        data: mountDataStack(late),
      },
    ],
  };
  return dataSource;
};
export const mountLabelStack = (data) => {
  const Arrylabel = [];
  if (data && data.length > 0) {
    data.forEach((data) => {
      Arrylabel.push({
        label: replaceMonth(data.duaDateMonth),
      });
    });
  }
  return Arrylabel;
};

export const mountDataStack = (data) => {
  const Arrylabel = [];
  if (data && data.length > 0) {
    data.forEach((data) => {
      var dataItem = data.amount;

      Arrylabel.push({
        value: dataItem,
      });
    });
  }
  return Arrylabel;
};
